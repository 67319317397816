import $ from "jquery/dist/jquery";
import * as base from "./base";

const topItemListHomeEl = document.getElementById('top-item-list-home');
const topItemAddonsListHomeEl = document.getElementById('top-item-addons-list-home');
const blogOneHomeEl = document.getElementById('blog-one-home');
const blogListHomeEl = document.getElementById('blog-list-home');


function initializeHomeViewerPage() {
    console.log('contact page has been initialized');

    topItemListHomeEl.innerHTML = '';
    topItemAddonsListHomeEl.innerHTML = '';
    handleGetDataTopItems(1);
    handleGetDataTopItems(2);
    handleGetDataTopItems(5);
    handleGetDataBlogItems();
  }
 
  const handleGetDataTopItems = (typeIItem) => {

    $.ajax({
      type: 'GET',
      url: `/home-item-json/${typeIItem}/`,
      success: function (response) {
        const data = response.data;

        setTimeout(() =>{        
            data.map(item => {
              let itemIsBundleLabel = '';
              switch (item.item_is_bundle) {
                  case 0:
                      itemIsBundleLabel = '';
                      break;
                  default:
                      itemIsBundleLabel = `<div class ="bundle-container-item">
                          <div class ="corner">
                                  <span>b</span>
                          </div>
                          <div class ="trapezoid">
                                  <span>bundle</span>
                          </div>
                  </div>`;
                      break;
              }
                if(item.type_item == 5)
                {
                    topItemAddonsListHomeEl.innerHTML += `<div class="lib-preview-item-thumbnail addons-item-home">
                    <a class="home-items-addon-preview-link" href="/viewer/${base.getTypeNameByVal(item.type_item)}/${item.item_id}/">
                        <img class="lib-img-full" src="${item.root_preview_small}"/>
                        ${itemIsBundleLabel}
                        <div class ="price-item">${item.price_item}</div>
                        <div class ="bottom-label-item">${item.hover_text}</div>
                    </a>
                </div>`;
                }
                else if(item.type_item == 1)
                {
                  let hoverType = '';
                  if(item.item_is_bundle == 0)
                  {
                    hoverType = `<div class ="bottom-label-item"><h6>${item.hover_text}</h6>${item.item_name}</div>`;
                  }
                  else{
                    hoverType = `<div class ="bottom-label-item"><h6><br/></h6>${item.item_name}</div>`;
                  }
    
    
                  topItemListHomeEl.innerHTML += `<div class="lib-preview-item-thumbnail">
                  <a class="home-item-preview-link" href="/viewer/${base.getTypeNameByVal(item.type_item)}/${item.item_id}/">
                      <img class="lib-img-full" src="${item.root_preview_small}" />
                      ${itemIsBundleLabel}
                      <div class ="price-item">${item.price_item}</div>
                      ${hoverType}
                  </a>
              </div>`;
                }
                else
                {
                    topItemListHomeEl.innerHTML += `<div class="lib-preview-item-thumbnail">
                    <a class="home-item-preview-link" href="/viewer/${base.getTypeNameByVal(item.type_item)}/${item.item_id}/">
                        <img class="lib-img-full" src="${item.root_preview_small}" />
                        ${itemIsBundleLabel}
                        <div class ="price-item">${item.price_item}</div>
                        <div class ="bottom-label-item">${item.hover_text}</div>
                    </a>
                </div>`;
                }
    
    
              });
    
          }, 100);
      },
      error: function (error) {
        console.log(error);
      }
    });
  };

  const handleGetDataBlogItems = () => {

    $.ajax({
      type: 'GET',
      url: `/home-blog-json/`,
      success: function (response) {
        const data = response.data;

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var optionsDate = {
          timeZone: timezone,
          timeStyle: "short",
          dateStyle: "short"
        };

        let numberItem = 0;
        setTimeout(() =>{        
            data.map(item => {
              let datePublic = new Intl.DateTimeFormat(undefined, optionsDate).format(new Date(item.date_public + '.000Z'));
              if(numberItem == 0)
              {
                blogOneHomeEl.innerHTML = `<div class="blog-item-base-container">
                <a class="lib-item-preview-link" href="/blog/${item.item_id}/">
                    <img class="blog-item-base-container__preview" src="${item.root_preview_small}" />
                </a>
                <div class="blog-item-base-container__date-public">${datePublic}</div>
                <a class="blog-item-base-container__name" href="/blog/${item.item_id}/">${item.item_name}</a>
                <div class="blog-item-base-container__text">${item.description}</div>
            </div>`;
            numberItem = 1;
              }
              else{
                blogListHomeEl.innerHTML += `<div class="blog-item-base-container compact-home">
                <div class="blog-item-base-container__date-public">${datePublic}</div>
                <a class="blog-item-base-container__name" href="/blog/${item.item_id}/">${item.item_name}</a>
                <div class="blog-item-base-container__text compact-home">${item.description}</div>
            </div>`;
              }
    
    
              });
    
          }, 100);
      },
      error: function (error) {
        console.log(error);
      }
    });
  };
 
  export { initializeHomeViewerPage };