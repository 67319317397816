import $ from "jquery/dist/jquery";
import { initializaCaptcha } from "./g-captcha";

const resetBlock = document.getElementById('reset-password-container');
const resetPasswordEl = document.getElementById('reset-password');
const resetPasswordValueEL = document.getElementById('reset-password-value');
const resetPasswordConfirmValueEL = document.getElementById('reset-password-confirm-value');
const statusResetPasswordEl = document.getElementById('status-reset-password');
const errorStatusResetPasswordEl = document.getElementById('error-status-reset-password');
const sendResetAgainEl = document.getElementById('send-reset-again');
const showIconEl = document.getElementById('showIcon');
const hideIconEl = document.getElementById('hideIcon');

const showConfirmIconEl = document.getElementById('showConfirmIcon');
const hideConfirmIconEl = document.getElementById('hideConfirmIcon');
const csrftoken = $("[name=csrfmiddlewaretoken]").val();

let CODE = '';
let showPassword = false;
let showConfirmPassword = false;

function initializeResetPasswordPage() {
    console.log('reset password page has been initialized');
    CODE = getCodeFromUrl(location.pathname);
    sendResetAgainEl.setAttribute("href", "/reset-password/" + CODE);
    initializaCaptcha();  
  
    resetPasswordEl.onclick = function (e) {
      e.preventDefault();    
      // eslint-disable-next-line no-undef
      grecaptcha.ready(function() {
        // eslint-disable-next-line no-undef
        grecaptcha.execute('6Ldt5IIkAAAAAFcSCpaUFLFNSXiIDG8Fzmwbp4ki', {action: 'submit'}).then(function(token) {
            console.log(token);
            sendResetData(token);
            return;
        });
      });      
    };

    showIconEl.onclick = function (e) {
      e.preventDefault();
      showPassword = !showPassword;
      onShowPasswordClick();
    };

    hideIconEl.onclick = function (e) {
      e.preventDefault();
      showPassword = !showPassword;
      onShowPasswordClick();
    };

    showConfirmIconEl.onclick = function (e) {
      e.preventDefault();
      showConfirmPassword = !showConfirmPassword;
      onShowConfirmPasswordClick();
    };

    hideConfirmIconEl.onclick = function (e) {
      e.preventDefault();
      showConfirmPassword = !showConfirmPassword;
      onShowConfirmPasswordClick();
    };
  }

  const onShowPasswordClick = () => {  
    if (showPassword) {
      showIconEl.classList.add("hidden");
      hideIconEl.classList.remove("hidden");
      resetPasswordValueEL.type = "text";
    } else {
      showIconEl.classList.remove("hidden");
      hideIconEl.classList.add("hidden");
      resetPasswordValueEL.type = "password";
    }
  };

  const onShowConfirmPasswordClick = () => {  
    if (showConfirmPassword) {
      showConfirmIconEl.classList.add("hidden");
      hideConfirmIconEl.classList.remove("hidden");
      resetPasswordConfirmValueEL.type = "text";
    } else {
      showConfirmIconEl.classList.remove("hidden");
      hideConfirmIconEl.classList.add("hidden");
      resetPasswordConfirmValueEL.type = "password";
    }
  };

  const getCodeFromUrl = (url) => {
    const regex = /\/reset-password\/(.+)\//;
    const match = url.match(regex);
    if (match) {
      return match[1];
    }
    console.error(`Error: Unable to parse URL in getUserIdFromUrl function`);
    return null;
  };

  const isValidPassword = (password) => {
    // Check password length
    if (password.length < 8 || password.length > 20) {
      return false;
    }

    // Check for spaces, special characters, and emoji
    // Allowed: ! @ # $ % ^ & * ( ) _ + - = [ ] { } ; ' : " \ | , . < > / ?
    // eslint-disable-next-line no-useless-escape
    var pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    if (!pattern.test(password)) {
      return false;
    }

    // Check for at least one letter and one number
    var hasLetter = false;
    var hasNumber = false;
    for (var i = 0; i < password.length; i++) {
      if (isNaN(password[i])) {
        hasLetter = true;
      } else {
        hasNumber = true;
      }
      if (hasLetter && hasNumber) {
        return true;
      }
    }
    return false;
  };
  
  const clearInputs =  () => {
    resetPasswordValueEL.value = "";
    resetPasswordConfirmValueEL.value = "";
  };

  const sendResetData = (captchaToken) => {
    const resetData = {
      password: resetPasswordValueEL.value ? resetPasswordValueEL.value : '',
      confirmPassword: resetPasswordConfirmValueEL.value ? resetPasswordConfirmValueEL.value : '',      
      code: CODE,
      captcha: captchaToken
    };
    if (resetData.password === '') {
      alert('Please provide a password. Your password is empty.');
      clearInputs();
      return;
    } 
    if (!isValidPassword(resetData.password)) {
      alert(`Please provide a correct password. Your password must be 8-20 characters long, contain letters, numbers and special characters (! @ # $ % ^ & * ( ) _ + - = [ ] { } ; ' : " \\ | , . < > / ?), and must not contain spaces or emoji`);
      clearInputs();  
      return;
    }
    if (resetData.confirmPassword === '') {
        alert('Please provide a password confirmation. Your confirm password is empty');
        clearInputs();
        return;
      }
    if (resetData.password !== resetData.confirmPassword) {
      alert('Confirm password does not match with password.');
      clearInputs();
      return;
    }
    
    $.ajax({
      type: 'POST',      
      url: `${window.location.href}`,
      headers: { "X-CSRFToken": csrftoken },
      data: resetData,
      success: function (response) {
        // eslint-disable-next-line no-unused-vars
        const data = response.data;        
        statusResetPasswordEl.setAttribute("style", "display: flex;");
        resetBlock.setAttribute("style", "display: none;");
  
        setTimeout(() => {}, 100);
      },
      error: function (error) {
        console.log(error);
        if (resetBlock && errorStatusResetPasswordEl) {
            resetBlock.setAttribute("style", "display: none;");
            errorStatusResetPasswordEl.setAttribute("style", "display: flex;");
          alert('Can not send request. Something went wrong.');
          return;
        }
      }
    });
  };

export {
    initializeResetPasswordPage
  };