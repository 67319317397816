import $ from "jquery/dist/jquery";

const listWindowsAppEl = document.getElementById('list-windows-app');
const listMacAppEl = document.getElementById('list-mac-app');
const listLinuxAppEl = document.getElementById('list-linux-app');
const appVersionEl = document.getElementById('app-version');
const newsReleaseEl = document.getElementById('news-release');
const listOsEl = document.getElementsByClassName('libitems-download-page-content__list-os');
const numberRegistrationsEl = document.getElementById('list-number-registrations');
const exceededRegistrationsEl = document.getElementById('list-exceeded-registrations');
const registrationInputEl = document.getElementById('registration-input-block');
const inputElement = document.getElementById('registration-code');
const buttonElement = document.getElementById('get-links-button');
const spinnerElement = document.getElementById('spinner-container');
const csrftoken = $("[name=csrfmiddlewaretoken]").val();

let keyCode = '';
function initializeDownloadViewerPage() {
  console.log('download page has been initialized');
  if (spinnerElement !== undefined) {
    spinnerElement.classList.remove('not-visible');
  }
  handleGetDownloadPageData();
}

function initializeDownloadByCodeViewerPage() {
  console.log('download by code page has been initialized');
  if (spinnerElement !== undefined) {
    spinnerElement.classList.remove('not-visible');
  }
  keyCode = getKeyCodeFromUrl(location.pathname);
  if (keyCode !== '') {
    handleGetDownloadByCodePageData(keyCode);
    bindEventsForDownloadButtons(keyCode);
  } else {
    showHtmlAboutNoCode();
  }
}

const bindEventsForDownloadButtons = (keyCode) => {
  $(document).on('click', 'a[data-os-version]', function (e) {
    e.preventDefault();

    const osVersion = $(this).data('os-version');
    const downloadUrl = $(this).attr('href');
    const downloadData = {
      access_code: keyCode,
      os_version: osVersion
    };

    $.post({
      type: 'POST',
      url: `${window.location.href}`,
      credentials: "same-origin",
      headers: { "X-CSRFToken": csrftoken },
      data: downloadData,
      success: function (response) {

        if (response.data.isStatLogged != undefined && response.data.isStatLogged) {
          let link = document.createElement('a');
          link.href = downloadUrl;
          link.download = downloadUrl.substring(downloadUrl.lastIndexOf('/') + 1);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        else {
          console.warn('Download link has not been logged');
        }

      },
      error: function (error) {
        console.log(error);
      }
    });
  });
};

const getKeyCodeFromUrl = (url) => {
  const regex = /\/download\/(.+)\//;
  const match = url.match(regex);
  if (match) {
    return match[1];
  }
  return '';
};

const showHtmlAboutNoCode = () => {
  exceededRegistrationsEl.classList.remove('not-visible');
  registrationInputEl.classList.remove('not-visible');

  if (spinnerElement !== undefined) {
    spinnerElement.classList.add('not-visible');
  }

  if (inputElement !== undefined && buttonElement !== undefined) {
    inputElement.addEventListener('keyup', function () {
      let parsedValue = this.value;
      buttonElement.href = `/download/${parsedValue}/`;
      buttonElement.removeAttribute('disabled');
    });
  }

  exceededRegistrationsEl.innerHTML =
    `<div class="libitems-download-page-content__list-os__list-install__not-available-registrations">
         <div class="aval-text">Links for downloading are not available without registration code.
        </div>
      </div>`
      + ``
      // `<div class="libitems-download-page-content__list-os__list-install__not-available-registrations">
      //    <div class="aval-text">
      //    If you don't have a registration code, use <a href="/contact/">contact form</a> to request it.
      //    </div>
      // </div>
      //`
      ;
};

const handleGetDownloadByCodePageData = (keyCode) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  $.ajax({
    type: 'GET',
    url: `/download-by-code-json/${timezone.replace('/', "_")}/${keyCode}/`,
    success: function (response) {
      const data = response.data;
      const update = response.update;
      const availableRegistrations = response.available_registrations;

      if (spinnerElement !== undefined) {
        spinnerElement.classList.add('not-visible');
      }

      if (listOsEl !== undefined && listOsEl !== null && listOsEl.length > 0 && availableRegistrations > 0) {
        listOsEl[0].classList.remove('not-visible');
      }

      if (availableRegistrations <= 0) {
        exceededRegistrationsEl.classList.remove('not-visible');
        exceededRegistrationsEl.innerHTML =
          `<div class="libitems-download-page-content__list-os__list-install__not-available-registrations">
             <div class="aval-text">The number of registrations for this code has been exceeded. Or the links for downloading
               are not available for this code.
            </div>
          </div>
          <div class="libitems-download-page-content__list-os__list-install__not-available-registrations">
             <div class="aval-text">
               Registration code: <span>${keyCode}</span>
             </div>
          </div>
          `;

        return;
      }

      appVersionEl.innerHTML = `Libitems app version ${data.version_app}`;

      const optionsDate = {
        timeZone: timezone,
        timeStyle: "short",
        dateStyle: "short"
      };

      newsReleaseEl.innerHTML = `<div class="blog-item-base-container__date-public">${new Intl.DateTimeFormat(undefined, optionsDate).format(new Date(data.date_public + '.000Z'))}</div>
      <a class="blog-item-base-container__name" href="/blog/${data.item_id}/">${data.item_name}</a>
      <div class="blog-item-base-container__text">${data.description}</div>`;

      if (numberRegistrationsEl)
        numberRegistrationsEl.innerHTML = `
      <div class="libitems-download-page-content__list-os__list-install__available-registrations">
        <div class="aval-text">Number of available registrations</div>
        <div class="aval-num">${availableRegistrations}</div>        
      </div>
      <div class="libitems-download-page-content__list-os__list-install__available-registrations">
        <div class="aval-text">Code of  registration</div>
        <div class="aval-num"><span>${keyCode}</span></div>
      </div>`;

      update.map(item => {


        let type_install = '';
        if (item.type_package == 1) {
          type_install = 'Installer';
        }
        else if (item.type_package == 2) {
          type_install = `Portable (.${item.file_extension})`;
        }
        else {
          type_install = 'not found type';
        }

        let dataHtml = `<div class="libitems-download-page-content__list-os__list-install__install">
          <div class="libitems-download-page-content__list-os__list-install__install__type">${type_install}</div>
          <div class="libitems-download-page-content__list-os__list-install__install__size">${item.size}</div>
          <div class="libitems-download-page-content__list-os__list-install__install__bth">
            <a class="lib-btn-primary" href="${item.url_file}" data-os-version="${item.os_type}">Download</a>
          </div>
        </div>`;

        if (item.os_type == 'windows') {
          listWindowsAppEl.innerHTML += dataHtml;
        }
        else if (item.os_type == 'mac') {
          listMacAppEl.innerHTML += dataHtml;
        }
        else if (item.os_type == 'linux') {
          listLinuxAppEl.innerHTML += dataHtml;
        }

      });

      setTimeout(() => {}, 100);
    },
    error: function (error) {
      console.log(error);
    }
  });
};

const handleGetDownloadPageData = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  $.ajax({
    type: 'GET',
    url: `/download-json/${timezone.replace('/', "_")}/`,
    success: function (response) {
      const data = response.data;
      const update = response.update;

      if (spinnerElement !== undefined) {
        spinnerElement.classList.add('not-visible');
      }

      appVersionEl.innerHTML = `Libitems app version ${data.version_app}`;

      var optionsDate = {
        timeZone: timezone,
        timeStyle: "short",
        dateStyle: "short"
      };

      newsReleaseEl.innerHTML = `<div class="blog-item-base-container__date-public">${new Intl.DateTimeFormat(undefined, optionsDate).format(new Date(data.date_public + '.000Z'))}</div>
      <a class="blog-item-base-container__name" href="/blog/${data.item_id}/">${data.item_name}</a>
      <div class="blog-item-base-container__text">${data.description}</div>`;

      update.map(item => {
        let type_install = '';
        if (item.type_package == 1) {
          type_install = 'Installer';
        }
        else if (item.type_package == 2) {
          type_install = `Portable (.${item.file_extension})`;
        }
        else {
          type_install = 'not found type';
        }

        let dataHtml = `<div class="libitems-download-page-content__list-os__list-install__install">
            <div class="libitems-download-page-content__list-os__list-install__install__type">${type_install}</div>
            <div class="libitems-download-page-content__list-os__list-install__install__size">${item.size}</div>
            <div class="libitems-download-page-content__list-os__list-install__install__bth"><a class="lib-btn-primary" href="${item.url_file}">Download</a></div>
          </div>`;

        if (item.os_type == 'windows') {
          listWindowsAppEl.innerHTML += dataHtml;
        }
        else if (item.os_type == 'mac') {
          listMacAppEl.innerHTML += dataHtml;
        }
        else if (item.os_type == 'linux') {
          listLinuxAppEl.innerHTML += dataHtml;
        }

      });

      setTimeout(() => {}, 100);
    },
    error: function (error) {
      console.log(error);
    }
  });
};

export { initializeDownloadViewerPage, initializeDownloadByCodeViewerPage };