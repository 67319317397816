import $ from "jquery/dist/jquery";
import { initializaCaptcha } from "./g-captcha";
const sendMailEl = document.getElementById('send-mail');
const contactBlock = document.getElementById('contact-message-container');
const contactMesssageEL = document.getElementById('contact-message-area');
const contactEmailEL = document.getElementById('contact-email');
const contactNameEL = document.getElementById('contact-name');
const statusSendMailEl = document.getElementById('status-send-mail');
const errorStatusSendMailEl = document.getElementById('error-status-send-mail');
const csrftoken = $("[name=csrfmiddlewaretoken]").val();

function initializeContactViewerPage() {
  console.log('contact page has been initialized');
  initializaCaptcha();  

  sendMailEl.onclick = function (e) {
    e.preventDefault();    
    // eslint-disable-next-line no-undef
    grecaptcha.ready(function() {
      // eslint-disable-next-line no-undef
      grecaptcha.execute('6Ldt5IIkAAAAAFcSCpaUFLFNSXiIDG8Fzmwbp4ki', {action: 'submit'}).then(function(token) {
          console.log(token);
          sendMailData(token);
          return;
      });
    });
    
  };
}

function isValidEmailAddress(emailAddress) {
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  return pattern.test(emailAddress);
}


const sendMailData = (captchaToken) => {
  //const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;  
  const contactData = {
    name: contactNameEL.value ? contactNameEL.value : '',
    email: contactEmailEL.value ? contactEmailEL.value : '',
    message: contactMesssageEL.value ? contactMesssageEL.value : '',
    captcha: captchaToken
  };
  if (contactData.name === '') {
    alert('Please provide a name. Your name is empty');
    return;
  }
  if (!isValidEmailAddress(contactData.email)) {
    alert('Please provide a valid email address');
    return;
  }
  if (contactData.message === '') {
    alert('Please provide a message. Your name is message');
    return;
  } 

  
  
  $.ajax({
    type: 'POST',
    url: `${window.location.href}`,
    headers: { "X-CSRFToken": csrftoken },    
    data: contactData,
    success: function (response) {
      // eslint-disable-next-line no-unused-vars
      const data = response.data;      
      statusSendMailEl.setAttribute("style", "display: flex;");
      contactBlock.setAttribute("style", "display: none;");

      setTimeout(() => {}, 100);
    },
    error: function (error) {
      console.log(error);
      const data = error.data;

      contactBlock.setAttribute("style", "display: none;");
      errorStatusSendMailEl.setAttribute("style", "display: flex;");

      if (!data.isMessagesent && !data.isCaptchaVerified) {
        alert('Can not send request. Google captcha has not been verified.');
      }
      if (contactBlock && errorStatusSendMailEl) {
        
        alert('Can not send request. Something went wrong.');
        return;
      }
    }
  });
};

export {
  initializeContactViewerPage
};