import $ from "jquery/dist/jquery";
import * as base from "./base";

const discoverContainer = document.getElementById('discover-list-view');
const spinnerContainer = document.getElementById('spinner-container');
const loadContainer = document.getElementById('loading-container');
const loadButton = document.getElementById('load-btn');
const searchInput = document.getElementById('search-input');
const searchClear = document.getElementById('search-clear');
const noResultsContainer = document.getElementById('no-results-container');
const number_per_request = 30;

let timeoutId;

let categoryTypeForTopItems = 2;
let clear_list_view = true;
let TYPE_PAGE = 'home';
let treeCategoryModels = '';
let treeCategoryMaterials = '';
let treeCategoryTextures = '';
let treeCategoryAddons = '';
let treeCategoryBlogs = '';
let currentViewCategoryId = 0;
let categoryIdFirstOpen = 0;
let TAG_SEARCH_PARAMS = undefined;
let startIndex = -1;

const category3dmodelsItem = document.getElementById('discover-category-3dmodels');
const categoryMaterialsItem = document.getElementById('discover-category-materials');
const categoryAddonsItem = document.getElementById('discover-category-addons');
const categoryBlogsItem = document.getElementById('discover-category-blogs');
const resetDiscoverListViewState = () => 
{
  resetIndexes();  
  clear_list_view = true;
};

const treeCategoriesContainer = document.getElementById('tree-categories-content');
const treeCategoriesItem = document.getElementsByClassName('lib-tree-item');

function initializeDiscoverPage() {
  resetIndexes();
  resetSearchInput();
  TAG_SEARCH_PARAMS = getTagSearchParametersFromUrl();

  TYPE_PAGE = getTypePageFromUrl(location.pathname);

  handleGetCategoriesCountData();
  bindEventsTopCategoryItems();
  
  searchInput.addEventListener('keyup', onInputChanged);
  searchClear.addEventListener('click', onInputCleared);

  if (TYPE_PAGE == 'home') {
    handleGetCategoriesTreeData(2, 2);
    handleGetDiscoverDataTopItems(categoryTypeForTopItems);
  } else {
    let url = location.href;
    if (location.hash) {
      const hash = url.split("#");
      categoryIdFirstOpen = hash[1];
    }
    
    getItemsByCatetogyType(TYPE_PAGE);
    
  }
  bindEventsToLoadMoreButton();
  bindEventsToTreeCategories();
}

const getTypePageFromUrl = (url) => {
  const regex = /\/discover\/(models|materials|addons|blog|tags)\//;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const getItemsByCatetogyType = (typePage) => {
  if (typePage == 'models') {
    category3dmodelsItem.click();
  } else if (typePage == 'materials') {
    categoryMaterialsItem.click();
  } else if (typePage == 'textures') {
    //
  } else if (typePage == 'addons') {
    categoryAddonsItem.click();
  } else if (typePage == 'blog') {
    categoryBlogsItem.click();
  }
  else if (typePage == 'tags') {
    handleSearchByTagName();
  }
};

const getTagSearchParametersFromUrl = (parsedItemType , parsedTagName) => {
  const params = new URLSearchParams(document.location.search);
  var typeItem = -1;
  var tagName = "";

  if (parsedItemType !== undefined) {
    typeItem = parsedItemType;
  } else if (params.get("type")){
    typeItem = base.getTypeIdByKey(params.get("type"));
  }

  if (parsedTagName !== undefined) {
    tagName = parsedTagName;
  } else if (params.get("tag")){
    tagName = params.get("tag").trim();
  }
  
  return { typeItem, tagName };
};

const resetIndexes = () => {
  startIndex = 0;
};

const resetSearchInput = () => {
  if (searchInput != undefined) {
    searchInput.value = '';    
  }
  if (searchClear != undefined) {
    searchClear.classList.remove('visible');
    searchClear.classList.add('hidden');
  }
  if (noResultsContainer != undefined) {
      noResultsContainer.innerHTML = '';
      searchClear.classList.add('not-visible');
  }
};

const checkHash = () =>
{
  let url = location.href;
  let hashTemp = '';
  if (location.hash) {
    const hash = url.split("#");
    hashTemp = `#${hash[1]}`;
  }
  return hashTemp;
};

const handleClickOnCategory3dmodelsItem = () =>
{
  changeTypeItemCategory(2);

  resetSearchInput();
  resetDiscoverListViewState();
  handleGetCategoriesTreeData(2, 2);

  const url = base.changeLocationPathname(base.TypeItem.Models);
  if(categoryIdFirstOpen == 0)
  {
    window.history.replaceState('', '', url);
    const models_category_id = 2;
    handleGetDiscoverDataTopItems(models_category_id);
  }
  else{
    window.history.replaceState('', '', `${url}${checkHash()}`);
  }
};

const handleClickOnCategoryMaterialsItem = () =>
{
  changeTypeItemCategory(1);

  resetSearchInput();
  resetDiscoverListViewState();
  handleGetCategoriesTreeData(1, 1);

  const url = base.changeLocationPathname(base.TypeItem.Materials);
  if(categoryIdFirstOpen == 0)
  {
    window.history.replaceState('', '', url);
    const materials_category_id = 1;
    handleGetDiscoverDataTopItems(materials_category_id);
  }
  else{
    window.history.replaceState('', '', `${url}${checkHash()}`);
  }
};

const handleClickOnCategoryAddonsItem = () =>
{
  changeTypeItemCategory(5);

  resetSearchInput();
  resetDiscoverListViewState();
  handleGetCategoriesTreeData(5, 104);

  const url = base.changeLocationPathname(base.TypeItem.Addons);
  if(categoryIdFirstOpen == 0)
  {
    window.history.replaceState('', '', url);
    const addons_category_id = 104;
    handleGetDiscoverDataTopItems(addons_category_id);
  }
  else{
    window.history.replaceState('', '', `${url}${checkHash()}`);
  }
};

const handleClickOnCategoryBlogsItem = () =>
{
  changeTypeItemCategory(21);
  
  resetSearchInput();  
  resetDiscoverListViewState();
  handleGetCategoriesTreeData(21, 106);

  const url = base.changeLocationPathname(base.TypeItem.Blog);
  if(categoryIdFirstOpen == 0)
  {
    window.history.replaceState('', '', url);
    const addons_category_id = 106;
    handleGetDiscoverDataTopItems(addons_category_id);
  }
  else{
    window.history.replaceState('', '', `${url}${checkHash()}`);
  }
};

const handleSearchByTagName = () =>
{  
  
  if (TAG_SEARCH_PARAMS == undefined || TAG_SEARCH_PARAMS.typeItem == -1 || TAG_SEARCH_PARAMS.tagName == "") {
    return;
  }
  changeTypeItemCategory(TAG_SEARCH_PARAMS.typeItem);
  
  resetDiscoverListViewState();
  handleGetDiscoverDataSearchByTagItems(TAG_SEARCH_PARAMS.typeItem, TAG_SEARCH_PARAMS.tagName);
};


const changeTypeItemCategory = (newTypeItemCategory) =>
{
  // очищаємо список до зміни типу ітема, так як виходить поганий ефект що тип ітема змінився а категорія ще не перезавантажилась
  if (clear_list_view) {
    discoverContainer.innerHTML = "";
  }

    if(categoryTypeForTopItems == 1)
    {
        discoverContainer.classList.remove('materials-item');
    }
    else if(categoryTypeForTopItems == 2)
    {
        discoverContainer.classList.remove('models-item');
    }
    else if(categoryTypeForTopItems == 3)
    {
        discoverContainer.classList.remove('textures-item');
    }
    else if(categoryTypeForTopItems == 5)
    {
        discoverContainer.classList.remove('addons-item');
    }
    else if(categoryTypeForTopItems == 21)
    {
        discoverContainer.classList.remove('blogs-item');
    }
  
    if(newTypeItemCategory == 1)
    {
        discoverContainer.classList.add('materials-item');
    }
    else if(newTypeItemCategory == 2)
    {
        discoverContainer.classList.add('models-item');
    }
    else if(newTypeItemCategory == 3)
    {
        discoverContainer.classList.add('textures-item');
    }
    else if(newTypeItemCategory == 5)
    {
        discoverContainer.classList.add('addons-item');
    }
    else if(newTypeItemCategory == 21)
    {
        discoverContainer.classList.add('blogs-item');
    }

    categoryTypeForTopItems = newTypeItemCategory;
};


const handleTreeCategoriesItemClick = (event) => {  
  const categoryId = event.target.getAttribute("data-value");
  if (categoryId == undefined || categoryId == null) {    
    return;
  }
  console.log('Tree item has been clicked');
  resetSearchInput();
  handleGetDiscoverDataByCategoryIdItems(event.target.getAttribute("data-value"));
};

const bindEventsTopCategoryItems = ()  => {
  category3dmodelsItem.addEventListener("click", handleClickOnCategory3dmodelsItem, false);
  categoryMaterialsItem.addEventListener("click", handleClickOnCategoryMaterialsItem, false);
  categoryAddonsItem.addEventListener("click", handleClickOnCategoryAddonsItem, false);
  categoryBlogsItem.addEventListener("click", handleClickOnCategoryBlogsItem, false);
};

const bindEventsToTreeCategories = () => {
  console.log("Invoked bindEventsToTreeCategories");

  for (let index = 0; index < treeCategoriesItem.length; index++) {
    const element = treeCategoriesItem[index];
    if (element.getAttribute("data-value") != undefined && element.getAttribute("data-value") != null) {
      element.addEventListener("click", handleTreeCategoriesItemClick, false);
    }

  }
  
  if(categoryIdFirstOpen != 0)
  {
    const categoryList = [].slice.call(document.querySelectorAll('a[data-value]'));
    for (let index = 0; index < categoryList.length; index++) {
      const element = categoryList[index];
      if (element.getAttribute("href").replace(/#/, "") == categoryIdFirstOpen ) {
        element.click();
        categoryIdFirstOpen = 0;
      }
    }
  }
};

const bindEventsToLoadMoreButton = () => {
  loadButton.addEventListener('click', () => {
    startIndex += number_per_request;    
    clear_list_view = false;
    handleGetDiscoverDataTopItems(categoryTypeForTopItems);
  });
};

  const handleGetDiscoverDataByCategoryIdItems = (categoryId) => {
    if (categoryId == undefined || categoryId == null) {
      console.log('Category id is undefined or null');
      return;
    }
    if(currentViewCategoryId != categoryId)
    {
      window.history.replaceState('', '', `#${categoryId}`);
      $.ajax({
        type: 'GET',    
        url: `/discover-items-json/${categoryId}/${startIndex}/${number_per_request}/`,
        success: function(response) {          
          let max_size = response.max;
          const data = response.data;
          currentViewCategoryId = categoryId;

          const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          var optionsDate = {
            timeZone: timezone,
            timeStyle: "short",
            dateStyle: "short"
        };

          if (clear_list_view) {
            discoverContainer.innerHTML = "";
          }
          spinnerContainer.classList.remove('not-visible');
          setTimeout(() =>{        
            data.map(item => {
              discoverContainer.innerHTML += base.itemDataForList(item, optionsDate,'discover');  
            });

            if (!max_size || data.length == 0) {              
              loadContainer.classList.add('not-visible');
              clear_list_view = true;
            } else {
              loadContainer.classList.remove('not-visible');
            }
            spinnerContainer.classList.add('not-visible');
          }, 100);     
        },
        error: function(error) {
          console.log(error);
        }
      });
    }
  };
  
  const handleGetCategoriesCountData = () => {
    $.ajax({
      type: 'GET',
      url: `/discover-categories-count-json/`,
      success: function(response) {
        const countCategory3dItems = response.category_count.models;
        const countCategoryMaterials = response.category_count.materials;
        const countCategoryAddons = response.category_count.addons;
        const countCategoryBlogs = response.category_count.blogs;
  
        if (category3dmodelsItem && countCategory3dItems) {        
          category3dmodelsItem.querySelector('.category-menu-item__count').innerText = countCategory3dItems;
        }
  
        if (countCategoryMaterials && categoryMaterialsItem) {        
          categoryMaterialsItem.querySelector('.category-menu-item__count').innerText = countCategoryMaterials;
        }
  
        if (countCategoryAddons && categoryAddonsItem) {        
          categoryAddonsItem.querySelector('.category-menu-item__count').innerText = countCategoryAddons;
        }
        if (countCategoryAddons && categoryBlogsItem) {        
          categoryBlogsItem.querySelector('.category-menu-item__count').innerText = countCategoryBlogs;
        }
      },
      error: function(error) {
        console.log(error);
      }
    });
  };
  
  const handleGetCategoriesTreeData = (typeItem, category_id) => {
    if(typeItem == 1 && treeCategoryMaterials != '')
    {
      treeCategoriesContainer.innerHTML = treeCategoryMaterials;
      bindEventsToTreeCategories();
    }
    else if(typeItem == 2 && treeCategoryModels != '')
    {
      treeCategoriesContainer.innerHTML = treeCategoryModels;
      bindEventsToTreeCategories();
    }
    else if(typeItem == 3 && treeCategoryTextures != '')
    {
      treeCategoriesContainer.innerHTML = treeCategoryTextures;
      bindEventsToTreeCategories();
    }
    else if(typeItem == 5 && treeCategoryAddons != '')
    {
      treeCategoriesContainer.innerHTML = treeCategoryAddons;
      bindEventsToTreeCategories();
    }
    else if(typeItem == 21 && treeCategoryBlogs != '')
    {
      treeCategoriesContainer.innerHTML = treeCategoryBlogs;
      bindEventsToTreeCategories();
    }
    else
    {
      $.ajax({
        type: 'GET',
        url: `/discover-categories-json/${typeItem}/`,
        success: function (response) {          
          const data = response.data;
          let htmlTree = `<div class="lib-tree-level"><span class="indent"></span><ul class="lib-tree-items-list">`;
          // setTimeout(() =>{        
          data.map(item => {
            if (item.root_id == category_id) {
              if (item.number_children == 0) {
                htmlTree += `<li><a href="#${item.category_id}" class="lib-tree-item" data-value="${item.category_id}">${item.name_category}<span class="lib-tree-item__count">${item.number_items}</span></a></li>`;
              }
              else {
                let htmlTreeTemp = categoriesTreeBuild(item.category_id, data);
  
                htmlTree += `<a href="#${item.category_id}" class="lib-tree-item lib-tree-item__top collapsed" data-bs-toggle="collapse" type="button" role="button" 
                            data-bs-target="#tree-${item.category_id}-collapse" aria-expanded="true" data-value="${item.category_id}"> ${item.name_category}
                                <span class="lib-tree-item__count">${item.number_items + htmlTreeTemp[1]}</span>
                                <div class="lib-tree-list-open">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="lib-icon lib-icon-size-18 lib-icon-no-fill" fill="#ff0000" stroke-width="2" height="18px" viewBox="0 0 24 24" width="18px">
                                        <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </div>
                                <div class="lib-tree-list-close">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="lib-icon lib-icon-size-18 lib-icon-no-fill" fill="#ff0000" stroke-width="2" height="18px" viewBox="0 0 24 24" width="18px">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                    </svg>
                                </div>
                            </a>
                            <div class="lib-tree-level collapse" id="tree-${item.category_id}-collapse">
                            <span class="indent"></span><ul class="lib-tree-items-list">`;
    
                htmlTree += htmlTreeTemp[0];
                htmlTree += `</ul></div>`;
              }
            }
          });
    
          htmlTree += `</ul></div>`;
          treeCategoriesContainer.innerHTML = htmlTree;

          if(typeItem == 1)
          {
            treeCategoryMaterials = htmlTree;
          }
          else if(typeItem == 2)
          {
            treeCategoryModels = htmlTree;
          }
          else if(typeItem == 3)
          {
            treeCategoryTextures = htmlTree;
          }
          else if(typeItem == 5)
          {
            treeCategoryAddons = htmlTree;
          }
          else if(typeItem == 21)
          {
            treeCategoryBlogs = htmlTree;
          }

          bindEventsToTreeCategories();
        },
        error: function (error) {
          console.log(error);
        }
      });
    }
  };


  function categoriesTreeBuild(rootId, dataTree) {
    let htmlTree = ``;
    var numberItemSub = 0;
    dataTree.map(item => {
  
      if (item.root_id == rootId) {
        if (item.number_children == 0) {
          numberItemSub += item.number_items;
          htmlTree += `<li><a href="#${item.category_id}" class="lib-tree-item" data-value="${item.category_id}">${item.name_category}<span class="lib-tree-item__count">${item.number_items}</span></a></li>`;
        }
        else {
          let htmlTreeTemp = categoriesTreeBuild(item.idcategory_items, dataTree);
          numberItemSub += item.number_items + htmlTreeTemp[1];
          htmlTree += `<a href="#${item.category_id}" class="lib-tree-item lib-tree-item__top collapsed" data-bs-toggle="collapse" type="button" role="button" 
                       data-bs-target="#tree-${item.category_id}-collapse" aria-expanded="true" data-value="${item.category_id}"> ${item.name_category} 
                           <span class="lib-tree-item__count">${item.number_items + htmlTreeTemp[1]}</span>
                           <div class="lib-tree-list-open">
                               <svg xmlns="http://www.w3.org/2000/svg" class="lib-icon lib-icon-size-18 lib-icon-no-fill" fill="#ff0000" stroke-width="2" height="18px" viewBox="0 0 24 24" width="18px">
                                   <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                               </svg>
                           </div>
                           <div class="lib-tree-list-close">
                              <svg xmlns="http://www.w3.org/2000/svg" class="lib-icon lib-icon-size-18 lib-icon-no-fill" fill="#ff0000" stroke-width="2" height="18px" viewBox="0 0 24 24" width="18px">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                              </svg>
                           </div>
                        </a>
                        <div class="lib-tree-level collapse" id="tree-${item.category_id}-collapse">
                        <span class="indent"></span><ul class="lib-tree-items-list">`;  
          htmlTree += htmlTreeTemp[0];
          htmlTree += `</ul></div>`;
        }
      }
    });
    return [htmlTree,numberItemSub];
  }

  const handleGetDiscoverDataTopItems = (catId) => {
    $.ajax({
      type: 'GET',
      url: `/discover-items-json/${catId}/${startIndex}/${number_per_request}/`,
      success: function(response) {        
        let is_load_more_visible = response.max;
        const data = response.data;   

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var optionsDate = {
          timeZone: timezone,
          timeStyle: "short",
          dateStyle: "short"
      };
  
        spinnerContainer.classList.remove('not-visible');
        if (clear_list_view) {
          discoverContainer.innerHTML = "";
        }
        setTimeout(() =>{        
          data.map(item => {
            discoverContainer.innerHTML += base.itemDataForList(item, optionsDate,'discover');
          });

          if (is_load_more_visible) {
            loadContainer.classList.remove('not-visible');
          } else {            
            loadContainer.classList.add('not-visible');
            clear_list_view = true;            
          }
          spinnerContainer.classList.add('not-visible');
        }, 100);
        if(data.length == 0) {
          loadContainer.classList.add('not-visible');
        }
      },
      error: function(error) {
        console.log(error);
      }
    });
  };

  const handleGetDiscoverDataSearchByTagItems = (typeItem, tagName) => {
    $.ajax({
      type: 'GET',
      url: `/discover-tag-json/${typeItem}/${tagName}/${startIndex}/${number_per_request}/`,
      success: function(response) {        
        let is_load_more_visible = response.max;
        const data = response.data;   

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var optionsDate = {
          timeZone: timezone,
          timeStyle: "short",
          dateStyle: "short"
      };
  
        spinnerContainer.classList.remove('not-visible');
        noResultsContainer.innerHTML = "";
        if (clear_list_view) {
          discoverContainer.innerHTML = "";
        }
        setTimeout(() =>{        
          data.map(item => {
            discoverContainer.innerHTML += base.itemDataForList(item, optionsDate,'discover');
          });

          if (is_load_more_visible) {
            loadContainer.classList.remove('not-visible');
          } else {            
            loadContainer.classList.add('not-visible');
            clear_list_view = true;            
          }
          spinnerContainer.classList.add('not-visible');
        }, 100);
        if(data.length == 0) {
          loadContainer.classList.add('not-visible');
          
          noResultsContainer.classList.remove('not-visible');          
          let el = document.createElement('p');
          el.innerHTML = `No exact matches found for: <b>${searchInput.value}</b>`;
          noResultsContainer.appendChild(el);
          el = document.createElement('p');
          el.innerHTML = `Try another search tag or browse our categories.`;
          noResultsContainer.appendChild(el);
        }
      },
      error: function(error) {
        console.log(error);
      }
    });
  };

  const onInputChanged = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {      
      TYPE_PAGE = getTypePageFromUrl(location.pathname);
      const typeItem = base.getTypeIdByKey(TYPE_PAGE);
      if (typeItem == -1) return;
      if (searchClear != undefined) {
        searchClear.classList.remove('hidden');
        searchClear.classList.add('visible');
      }        
      TAG_SEARCH_PARAMS = getTagSearchParametersFromUrl(typeItem, searchInput.value);      
      handleSearchByTagName();
    }, 500);
  };

  const onInputCleared = () => {
    resetSearchInput();

    TYPE_PAGE = getTypePageFromUrl(location.pathname);
    getItemsByCatetogyType(TYPE_PAGE);
  };



export {initializeDiscoverPage};
