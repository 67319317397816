//import $ from "jquery/dist/jquery";
//import * as base from "./base";
//const descriptionContentEl = document.getElementById('description-content');
const htuWrapperEl = document.getElementsByClassName('howtouse-wrapper');
//const spinnerContainer = document.getElementById('spinner-container');
const urlParams = { id: "", type: "", style: ""};

function initializeDescriptionPage() {
  handleGetHowToUseItems();
}

const handleGetHowToUseItems = () => {  
  parseUrlParams();
  if (urlParams.id === "") {
    console.warn(`Id is empty`);
    return;
  }
  if (urlParams.style === 'black' && htuWrapperEl != undefined) {
    changeBackgroundToBlack();
  }

  // $.ajax({
  //   type: 'GET',
  //   //url: `${window.location.href}`,    
  //   url: `${window.location.href.replace('viewer-desc', 'viewer-desc-json')}`,
  //   success: function (response) {      
  //     setTimeout(() => {
  //       spinnerContainer.classList.add('not-visible');
  //       descriptionContentEl.classList.remove('not-visible');
  //       descriptionContentEl.innerHTML = response.data;
  //     }, 500);    
  //   },
  //   error: function (error) {
  //     console.log(error);
  //   }
  // });
};

const parseUrlParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (params?.id != undefined) {
    urlParams.id = params.id;
  }
  if (params?.type != undefined) {
    urlParams.type = params.type;
  }
  if (params?.style != undefined) {
    urlParams.style = params.style;
  }

};

const changeBackgroundToBlack = () => {
  htuWrapperEl[0].style.background  = "#1d1d1d";
  htuWrapperEl[0].style.color  = "white";
  const bodyEl = document.getElementsByTagName('body');
  bodyEl[0].style.background  = "#1d1d1d";

  walkThoughChilds(htuWrapperEl[0]);
};

const walkThoughChilds = (el) => {
  changeColorToWhite(el);
  Array.from(el.children).forEach(walkThoughChilds);
};

const changeColorToWhite = (child) => {
  if (child.style != undefined) {
    if (child.style.color != undefined) {
      child.style.color = 'white';
    }
  }
};

export {
  initializeDescriptionPage
};