import $ from "jquery/dist/jquery";
import { itemDataForList } from "./base";

const blogListEl = document.getElementById('blog-list-view');
const blogPageItemLoadBtnEl = document.getElementById('blog-page-item-load-btn');
const blogLoadContainer = document.getElementById('blog-loading-container');

const initial_number_per_request = 20;
let numberBlogItemView = initial_number_per_request;
//let clear_list_blog_view = true;

function initializeBlogViewerPage() {
    console.log('blog page has been initialized');
  
    handleGetBlogPageData();

    blogPageItemLoadBtnEl.addEventListener('click', () => {
      numberBlogItemView += initial_number_per_request;
      //clear_list_blog_view = false;
      handleGetBlogPageData();
      });
  }

  const handleGetBlogPageData = () => {
    $.ajax({
      type: 'GET',
      url: `/blog-article-list-json/${numberBlogItemView}/`,
      success: function (response) {
        const data = response.data;
        let max_size = response.max;  

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var optionsDate = {
          timeZone: timezone,
          timeStyle: "short",
          dateStyle: "short"
        };

        setTimeout(() =>{        
            data.map(item => {            
              blogListEl.innerHTML += itemDataForList(item, optionsDate);
            });
            if (max_size) {            
              blogLoadContainer.classList.add('not-visible');              
            } else {
              blogLoadContainer.classList.remove('not-visible');
            }
          }, 100);
      },
      error: function (error) {
        console.log(error);
      }
    });
  };

  export { initializeBlogViewerPage };