import "../styles/index.scss";

import $ from "jquery/dist/jquery";
import "bootstrap/dist/js/bootstrap.bundle";
import { initializeDiscoverPage } from "./discover";
import { initializeProfilePage } from "./profile";
import { initializeViewerPage } from "./viewer";
import { initializeArticlieViewerPage } from "./article";
import { initializeBlogViewerPage } from "./blog";
import { initializeDownloadViewerPage, initializeDownloadByCodeViewerPage } from "./download";
import { initializeContactViewerPage } from "./contact";
import { initializeHomeViewerPage } from "./home";
import { initializeDocumentationViewerPage } from "./manuallib";
import { initializeHowToUsePage } from "./howtouse";
import { initializeHtuDescriptionPage } from "./htu-description";
import { initializeDescriptionPage } from "./description";
import { initializeResetPasswordPage } from "./reset-password";

const discoverPageBlock = document.getElementById('discover-page-block');
const profilePageBlock = document.getElementById('profile-page-block');
const viewerPageBlock = document.getElementById('viewer-page-block');
const articlePageBlock = document.getElementById('article-page-block');
const blogPageBlock = document.getElementById('blog-page-block');
const downloadPageBlock = document.getElementById('download-page-block');
const downloadByCodePageBlock = document.getElementById('download-by-code-page-block');
const contactPageBlock = document.getElementById('contact-page-block');
const homePageBlock = document.getElementById('home-page-block');
const documentationPageBlock = document.getElementById('documentation-page-block');
const howtousePageBlock = document.getElementById('howtouse-page-block');
const htuDescPageBlock = document.getElementById('htu-description-page-block');
const descPageBlock = document.getElementById('description-page-block');
const resetPageBlock = document.getElementById('reset-password-page-block');


$(document).ready(function () {
  window.console.log("dom ready for libitems");

  if (homePageBlock) {
    initializeHomeViewerPage();
  }
  if (discoverPageBlock) {
    initializeDiscoverPage();
  }
  if (profilePageBlock) {
    initializeProfilePage();
  }
  if (viewerPageBlock) {
    initializeViewerPage();
  }
  if (articlePageBlock) {
    initializeArticlieViewerPage();
  }
  if (blogPageBlock) {
    initializeBlogViewerPage();
  }
  if (downloadPageBlock) {
    initializeDownloadViewerPage();
  }
  if (downloadByCodePageBlock) {
    initializeDownloadByCodeViewerPage();
  }  
  if (contactPageBlock) {
    initializeContactViewerPage();
  }
  if (documentationPageBlock){
    initializeDocumentationViewerPage();
  }
  if(howtousePageBlock){
    initializeHowToUsePage();
  }
  if(htuDescPageBlock){
    initializeHtuDescriptionPage();
  }
  if(descPageBlock){
    initializeDescriptionPage();
  }
  if(resetPageBlock){
    initializeResetPasswordPage();
  }
});

