import $ from "jquery/dist/jquery";
import * as comments from "./comments";
import * as base from "./base";
import { ItemTypePair } from "./base";

const itemNameEl = document.getElementById('item-name');

const numberPurchasedItemEl = document.getElementById('item-number-purchased');
const dataPublicItemEl = document.getElementById('item-data-public');

const itemTagEl = document.getElementById('item-tag');

const ratingItemProgressbarEl = document.getElementById('item-rating-progressbar');
const ratingNumberPlusItemEl = document.getElementById('item-rating-number-plus');
const ratingNumberMinusItemEl = document.getElementById('item-rating-number-minus');

const priceItemElementEl = document.getElementById('item-price-element');
const priceBundleItemElementEl = document.getElementById('item-price-bundle-element');
const canBePurchasedAsRealObjectEl = document.getElementById('item-real-object-element');

const rootPreviewBigEl = document.getElementById('item-data-root-preview-big-img');

const userNameEl = document.getElementById('user-name');
const userAvatarEl = document.getElementById('user-avatar-img');

const itemElementsEl = document.getElementById('item-elements-list');

const bundleListItemsEl = document.getElementById('bundle-items-list');
const itemDescriptionEl = document.getElementById('items-description');

const previewBigListEl = document.getElementById('items-preview-big-list');
const previewSmallListEl = document.getElementById('items-preview-small-list');
const licensedTextItemEl = document.getElementById('items-licensed-text');

const itemViewerDescriptionAddonsMenuViewEl = document.getElementById('item-viewer-description-addons-menu-view');
const itemViewerDescriptionMenuViewEl = document.getElementById('item-viewer-description-menu-view');
const blogListEl = document.getElementById('blog-list-item');
const changelogListItemEl = document.getElementById('changelog-list-item');
const howToUseListItemEl = document.getElementById('howtouse-list-item');
const viewerItemBlogLoadContainerEl = document.getElementById('viewer-item-blog-load-container');
const viewerItemChangelogLoadContainerEl = document.getElementById('viewer-item-changelog-load-container');
const viewerItemBlogLoadBtnEl = document.getElementById('viewer-item-blog-load-btn');
const viewerItemChangelogLoadBtnEl = document.getElementById('viewer-item-changelog-load-btn');

const commentsListItemEl = document.getElementById('comments-list-item');
const descriptionTabEl = document.getElementById('description-tab');

const viewerItemSocialNetworkPinterestEL = document.getElementById('viewer-item-social-network-pinterest');
const viewerItemSocialNetworkFacebookEL = document.getElementById('viewer-item-social-network-facebook');
const viewerItemSocialNetworkTwitterEL = document.getElementById('viewer-item-social-network-twitter');
const viewerItemSocialNetworkLinkedinEL = document.getElementById('viewer-item-social-network-linkedin');
const viewerItemSocialNetworkLibitemsAppEL = document.getElementById('viewer-item-social-network-libitems-app');
const viewerItemSocialNetworkInstagramEL = document.getElementById('viewer-item-social-network-instagram');

const tabNumbers = {
  tabNumberBlog: 0,
  tabNumberManual: 0,
  tabNumberHowToUse: 0,
  tabNumberBundleItems: 0,
  tabNumberElements: 0,
  tabNumberSubscriptions: 0
};
const tabList = [].slice.call(document.querySelectorAll('a[data-bs-toggle="tab"]'));

const initial_number_per_request = 20;
let numberBlogItemView = 0;
//let clear_list_blog_view = true;
let numberChangelogItemView = 0;
//let clear_list_changelog_view = true;
let TYPE_ITEM = 0;
let ITEM_ID = 0;
let USER_ID = 0;


function initializeViewerPage() {
  console.log('viewer page has been initialized');  
  const idTypePair = getItemTypePairFromUrl(location.pathname);
  TYPE_ITEM = idTypePair.type;
  ITEM_ID = idTypePair.id;
  handleGetItemData(TYPE_ITEM, ITEM_ID);

  
  // event on click of tab
  tabList.forEach(function (tab) {   
  tab.addEventListener("shown.bs.tab", function (e) {    
    if (e.target.id == 'blog-tab') {
      if (numberBlogItemView == 0) {
        numberBlogItemView = initial_number_per_request;
        handleGetItemBlogData();
      }
    }
    if(e.target.id == 'manual-tab')
        {
          window.open(`/manual/addons/${ITEM_ID}/`);
        }
    if (e.target.id == 'changelog-tab') {
      if (numberChangelogItemView == 0) {
        numberChangelogItemView = initial_number_per_request;
        const spinnerContainer = document.getElementById('spinner-changelog');
        spinnerContainer.classList.remove('not-visible');
        handleGetItemChangelogData();
      }
    }
    if (e.target.id == 'howtouse-tab') {
        const spinnerContainer = document.getElementById('spinner-howtouse');
        spinnerContainer.classList.remove('not-visible');
        handleGetItemHowToUseData();
    }
  });
});

  viewerItemBlogLoadBtnEl.addEventListener('click', () => {
    numberBlogItemView += initial_number_per_request;
    //clear_list_blog_view = false;
    handleGetItemBlogData();
  });

  viewerItemChangelogLoadBtnEl.addEventListener('click', () => {
    numberChangelogItemView += initial_number_per_request;
    //clear_list_changelog_view = false;
    handleGetItemChangelogData();
  });
}


const setTabVisibility = () => {
  tabList.forEach( (tab) => {
    if (tab.id == 'howtouse-tab' && tab.parentElement != undefined && tabNumbers.tabNumberHowToUse == 0) {
      tab.parentElement.classList.add('hide-link');
    } 
    if (tab.id == 'blog-tab' && tab.parentElement != undefined && tabNumbers.tabNumberBlog == 0) {
      tab.parentElement.classList.add('hide-link');
    } 
    if (tab.id == 'manual-tab' && tab.parentElement != undefined && tabNumbers.tabNumberManual == 0) {
      tab.parentElement.classList.add('hide-link');
    } 
  });  
};

const getItemTypePairFromUrl = (url) => {
  const regex = /\/viewer\/(\w+)\/(\d+)\//;
  const match = url.match(regex);
  if (match) {
    const idTypePair = new ItemTypePair(match[1], match[2]);
    return idTypePair;
  }
  console.error(`Error: Unable to parse URL in getItemTypePairFromUrl function`);
  return null;  
};


const handleGetItemChangelogData = () => {
  $.ajax({
    type: 'GET',
    url: `/viewitem-list-item-changelog-json/${TYPE_ITEM}/${ITEM_ID}/${numberChangelogItemView}/`,
    success: function (response) {
      let max_size = response.max;
      const data = response.data;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      var optionsDate = {
        timeZone: timezone,
        timeStyle: "short",
        dateStyle: "short"
      };
      setTimeout(() => {
        data.map(item => {
          var datePublicConvert = new Intl.DateTimeFormat(undefined, optionsDate).format(new Date(item.date_public + '.000Z'));

          changelogListItemEl.innerHTML += `<div class="changelog-item-container">
                                              <a class="lib-item-preview-link" href="/changelog/addons/${ITEM_ID}/${item.item_id}/">
                                              
                                              <div class="changelog-item-container__name">${item.item_name}</div></a>
                                              <div class="changelog-item-container__status-row">
                                              <div class="changelog-item-container__status-row__comments-type-content" id="item-comments-type">
                                              <div class="changelog-item-container__status-row__comments-type-content__comment-type">Comments: ${item.number_comments}</div>
                                              <div class="changelog-item-container__status-row__comments-type-content__comment-type">Bug: (${item.number_bug_accept}/${item.number_bug_not_accept})</div>
                                              <div class="changelog-item-container__status-row__comments-type-content__comment-type">Question: ${item.number_question}</div>
                                              <div class="changelog-item-container__status-row__comments-type-content__comment-type">Proposal: ${item.number_proposal}</div>
                                              <div class="changelog-item-container__status-row__comments-type-content__comment-type">Review: ${item.number_review}</div>
                                              <div class="changelog-item-container__status-row__comments-type-content__comment-type">Resolution: ${item.number_resolution}</div>
                                          </div> 
                                              <div class="changelog-item-container__status-row__date-public">${datePublicConvert}</div>
                                              </div>
                                          </div>`;
        });
        if (!max_size || data.length == 0) {          
          viewerItemChangelogLoadContainerEl.classList.add('not-visible');
        } else {
          viewerItemChangelogLoadContainerEl.classList.remove('not-visible');
        }
        const spinnerContainer = document.getElementById('spinner-changelog');
        spinnerContainer.classList.add('not-visible');
      }, 100);
    },
    error: function (error) {
      console.log(error);
    }
  });
};


const handleGetItemHowToUseData = () => {
  $.ajax({
    type: 'GET',
    url: `/how-to-use-item-json/${TYPE_ITEM}/${ITEM_ID}/`,
    success: function (response) {
      //let max_size = response.max;
      const data = response.data;
      setTimeout(() => {
        howToUseListItemEl.innerHTML = '';
        data.map(item => {
          howToUseListItemEl.innerHTML += base.howToUseItems(item, true, base.getTypeNameByVal(parseInt(TYPE_ITEM)));
        });
        base.bindOnClickEventPlayAnimation();
        const spinnerContainer = document.getElementById('spinner-howtouse');
        spinnerContainer.classList.add('not-visible');
      }, 100);
    },
    error: function (error) {
      console.log(error);
    }
  });
};

const handleGetItemBlogData = () => {
  $.ajax({
    type: 'GET',
    url: `/viewitem-list-item-blog-json/${TYPE_ITEM}/${ITEM_ID}/${numberBlogItemView}/`,
    success: function (response) {
      let max_size = response.max;
      const data = response.data;

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      var optionsDate = {
        timeZone: timezone,
        timeStyle: "short",
        dateStyle: "short"
      };

      setTimeout(() => {
        data.map(item => {
          var datePublicConvert = new Intl.DateTimeFormat(undefined, optionsDate).format(new Date(item.date_public + '.000Z'));

          blogListEl.innerHTML += `<div class="blog-item-base-container">
                                              <a class="lib-item-preview-link" href="/blog/${item.item_id}/">
                                                  <img class="blog-item-base-container__preview" src="${item.root_preview_small}" />
                                              </a>
                                              <div class="blog-item-base-container__date-public">${datePublicConvert}</div>
                                              <a class="blog-item-base-container__name" href="/blog/${item.item_id}/">${item.item_name}</a>
                                              <div class="blog-item-base-container__text">${item.description}</div>
                                          </div>`;
        });

        if (!max_size || data.length == 0) {          
          viewerItemBlogLoadContainerEl.classList.add('not-visible');          
        } else {
          viewerItemBlogLoadContainerEl.classList.remove('not-visible');
        }

        const spinnerContainer = document.getElementById('spinner-blog');
        spinnerContainer.classList.add('not-visible');

      }, 100);
    },
    error: function (error) {
      console.log(error);
    }
  });
};




const handleGetItemData = (typeItem, itemId) => {
  $.ajax({
    type: 'GET',
    url: `/viewitem-json/${typeItem}/${itemId}/`,
    success: function (response) {
      const data = response.data;

      USER_ID = data.user_id;
      itemNameEl.innerText = data.item_name;

      if (rootPreviewBigEl && data.root_preview && data.root_preview != '') {
        rootPreviewBigEl.children[0].setAttribute("style", "display: none;");
        let previewImg = document.createElement("img");
        previewImg.setAttribute("src", data.root_preview);
        previewImg.setAttribute("class", " ");
        rootPreviewBigEl.append(previewImg);
      }

      licensedTextItemEl.innerHTML = data.licensed_text;

      if (data.description) {
        itemDescriptionEl.innerHTML = data.description;
      }
      else
      {
        descriptionTabEl.innerText = "Comments";
      }

      if (data.tab_number_blog != undefined)
        tabNumbers.tabNumberBlog = data.tab_number_blog;
      if (data.tab_number_manual != undefined)
        tabNumbers.tabNumberManual = data.tab_number_manual;
      if (data.tab_number_how_to_use != undefined)
      tabNumbers.tabNumberHowToUse = data.tab_number_how_to_use;
      if (data.tab_number_bundle_items != undefined)
        tabNumbers.tabNumberBundleItems = data.tab_number_bundle_items;
      if (data.tab_number_elements != undefined)
        tabNumbers.tabNumberElements = data.tab_number_elements;
      if (data.tab_number_subscriptions != undefined)
        tabNumbers.tabNumberSubscriptions = data.tab_number_subscriptions;

      setTabVisibility();
      previewItemData(data);
      priceItemData(data);
      ratingItemData(data);
      userPublisherData(data);

      setSociaShareElement(typeItem, itemId, data.item_name, data.root_preview_small, data.user_name);

      canBePurchasedAsRealObjectData(data);

      numberPurchasedItemEl.innerHTML = data.number_purchased_item;


      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      var optionsDate = {
        timeZone: timezone,
        dateStyle: "short"
      };
      dataPublicItemEl.innerHTML = new Intl.DateTimeFormat(undefined, optionsDate).format(new Date(data.date_public + '.000Z'));


      if (data.item_is_bundle) {
        handleGetItemBundleElementsData(typeItem, itemId, data.number_elements);
      }
      else {
        if (data.type_item == 1) {
          handleGetItemMaterialElementsData(data);
        }
        else {
          if (data.number_elements != 0) {
            handleGetItemElementsData(typeItem, itemId, data.number_elements, data.type_item_view);
          }
        }
      }

      tagListItem(data.type_item, data.tag_item);


      if (data.type_item == 5) {
        itemViewerDescriptionAddonsMenuViewEl.className = 'viewer-description-addons-menu-wrapper show-menu';
      } else {
        if (data.tab_number_how_to_use > 0)
          itemViewerDescriptionMenuViewEl.className = 'viewer-description-addons-menu-wrapper show-menu';
      }

      if(data.number_comments != 0)
      {
        comments.handleGetItemCommentsData(commentsListItemEl, typeItem, itemId, USER_ID);
      }
    },
    error: function (error) {
      console.log(error);
    }
  });
};

const tagListItem = (itemType, tagData) => {
  if (tagData == undefined || tagData == null || tagData == '')
    return;

    const tagArray = tagData.split(",");
  tagArray.map(tagName => {
    itemTagEl.innerHTML += `<a class="viewer-tag-content__item" href="/discover/tags/?type=${base.getTypeNameByVal(itemType)}&tag=${tagName.trim()}">${tagName.trim()}</a>`;

  });
};

const previewItemData = (data) => {

  previewBigListEl.innerHTML += `<div data-slide-number="0" class="carousel-item active"><img src="${data.root_preview}" alt="0 slide" class="d-block w-100 img-preview"></div>`;

  if (data.number_other_preview == 0) {
    previewSmallListEl.setAttribute("style", "display: none;");
  }
  else {
    previewSmallListEl.innerHTML += `<li class="list-inline-item-review active"><a class="small-preview" id="carousel-selector-0" data-bs-slide-to="0" data-bs-target="#previewCarousel" class="selected"><img src="${data.root_preview_small}" class="d-block small-thumbnail"></a></li>`;

    data.preview_list.map((item, index) => {
      previewBigListEl.innerHTML += `<div data-slide-number="${index + 1}" class="carousel-item"><img src="${item.name_image}" alt="${index + 1} slide" class="d-block w-100 img-preview"></div>`;
      previewSmallListEl.innerHTML += `<li class="list-inline-item-review"><a class="small-preview" id="carousel-selector-${index + 1}" data-bs-slide-to="${index + 1}" data-bs-target="#previewCarousel"><img src="${item.name_image_preview}" class="d-block small-thumbnail"></a></li>`;
    });
  }
};


const userPublisherData = (data) => {
  userNameEl.innerHTML = `<a href="${data.user_profile_url}">${data.user_name}</a>`;
  if (userAvatarEl && data.user_avatar_small && data.user_avatar_small != '') {
    let avatarImg = document.createElement("img");
    avatarImg.setAttribute("src", data.user_avatar_small);
    avatarImg.setAttribute("class", " ");
    let avatarUrlProfile = document.createElement("a");
    avatarUrlProfile.setAttribute("href", data.user_profile_url);
    avatarUrlProfile.append(avatarImg);
    userAvatarEl.append(avatarUrlProfile);
  }
};

const ratingItemData = (data) => {
  ratingNumberPlusItemEl.innerHTML = data.rating_item_plus;
  ratingNumberMinusItemEl.innerHTML = data.rating_item_minus;

  ratingItemProgressbarEl.innerHTML = base.ratingProgressbarItemData(data);
};

const priceItemData = (data) => {

  var priceItem = '';
  var priceBundle = '';
  var priceCurrency = data.price_currency;

  if (data.price_item == 0) {
    priceItem = 'Free';
    priceCurrency = '';
  }
  else {
    priceItem = data.price_item;
  }


  if (data.price_bundle_item == 0) {
    priceBundle = 'Free';
    priceCurrency = '';
  }
  else {
    priceBundle = data.price_bundle_item;
  }

  if (data.type_item_view == 4) {
    priceBundleItemElementEl.innerHTML = `<div class="viewer-content-right-base-container right-base-container-bundle-info">
    <div class="viewer-content-right-bundle-info">
        <div class="viewer-content-right-bundle-info__row">
                <div class="viewer-content-right-bundle-info__row__title">
                    Bundle
                </div>
                <div class="viewer-content-right-bundle-info__row__text">
                    ${priceBundle}
                </div>
        </div>
            <a class="lib-btn-secondary viewer-content-right-bundle-info__bth" href="/viewer/${base.getTypeNameByVal(data.type_item)}/${data.item_bundle_id}/">
                About
            </a>
    </div>
</div>`;
  }
  else if (data.type_item_view == 0 || data.type_item_view == 1 || data.type_item_view == 2 || data.type_item_view == 3) {
    if (data.item_bundle_id == 0) {
      priceItemElementEl.innerHTML += `<div class="viewer-content-right-base-container right-base-container-price-item right-base-container-price-info-single-item">
      <div class="viewer-content-right-price">
          <div class="viewer-content-right-price__row">
                  <div class="viewer-content-right-price__row__text">
                      ${priceItem} ${priceCurrency}
                  </div>
          </div>
              <a class="lib-btn-primary viewer-content-right-price__bth" href="libitems://libitems.com/viewer/${base.getTypeNameByVal(TYPE_ITEM)}/${ITEM_ID}?card=true">
                  Add to cart
              </a>
      </div>
  </div>`;
    }
    else {
      if (data.price_item == 0) {
        priceItemElementEl.innerHTML += `<div class="viewer-content-right-base-container right-base-container-price-item right-base-container-price-info-single-item">
        <div class="viewer-content-right-price">
            <div class="viewer-content-right-price__row">
                    <div class="viewer-content-right-price__row__title">
                        Single item
                    </div>
                    <div class="viewer-content-right-price__row__text">
                      ${priceItem} ${priceCurrency}
                    </div>
            </div>
                <a class="lib-btn-primary viewer-content-right-price__bth" href="libitems://libitems.com/viewer/${base.getTypeNameByVal(TYPE_ITEM)}/${ITEM_ID}?card=true">
                    Add to cart
                </a>
        </div>
    </div>`;
      }
      else {
        priceItemElementEl.innerHTML += `<div class="viewer-content-right-base-container right-base-container-price-item">
        <div class="viewer-content-right-price">
            <div class="viewer-content-right-price__row">
                    <div class="viewer-content-right-price__row__title">
                        Single item
                    </div>
                    <div class="viewer-content-right-price__row__text">
                      ${priceItem} ${priceCurrency}
                    </div>
                    <div class="viewer-content-right-price__row__in-bundle">
                        ${data.price_item_in_group} ${priceCurrency}* (${data.price_for_item_percentages}%)
                    </div>
                    <div class="viewer-content-right-price__row__in-bundle-remark">
                        *price if buy bundle
                    </div>
            </div>
                <a class="lib-btn-primary viewer-content-right-price__bth" href="libitems://libitems.com/viewer/${base.getTypeNameByVal(TYPE_ITEM)}/${ITEM_ID}?card=true">
                    Add to cart
                </a>
        </div>
    </div>`;
      }
    }
  }

  if (data.type_item_view == 5) {
    if (data.price_item == 0) {
      priceItemElementEl.innerHTML += `<div class="viewer-content-right-base-container right-base-container-price-item right-base-container-price-info-single-item">
      <div class="viewer-content-right-price">
          <div class="viewer-content-right-price__row">
                  <div class="viewer-content-right-price__row__text">
                    ${priceItem} ${priceCurrency}
                  </div>
          </div>
              <a class="lib-btn-primary viewer-content-right-price__bth" href="libitems://libitems.com/viewer/${base.getTypeNameByVal(TYPE_ITEM)}/${ITEM_ID}?card=true">
                  Add to cart
              </a>
      </div>
  </div>`;
    }
    else {
      priceItemElementEl.innerHTML += `<div class="viewer-content-right-base-container right-base-container-price-item">
    <div class="viewer-content-right-price">
        <div class="viewer-content-right-price__row">
                <div class="viewer-content-right-price__row__title">
                    Single item
                </div>
                <div class="viewer-content-right-price__row__text">
                  ${priceItem} ${priceCurrency}
                </div>
                <div class="viewer-content-right-price__row__in-bundle">
                    ${data.price_item_in_group} ${priceCurrency}* (${data.price_for_item_percentages}%)
                </div>
                <div class="viewer-content-right-price__row__in-bundle-remark">
                    *price if buy bundle
                </div>
        </div>
            <a class="lib-btn-primary viewer-content-right-price__bth" href="libitems://libitems.com/viewer/${base.getTypeNameByVal(TYPE_ITEM)}/${ITEM_ID}?card=true">
                Add to cart
            </a>
    </div>
</div>`;
    }
  }

  if (data.type_item_view_bundle == 5) {
    priceBundleItemElementEl.innerHTML = `<div class="viewer-content-right-base-container right-base-container-bundle-info">
    <div class="viewer-content-right-bundle-info">
        <div class="viewer-content-right-bundle-info__row">
                <div class="viewer-content-right-bundle-info__row__title">
                    Bundle
                </div>
                <div class="viewer-content-right-bundle-info__row__text">
                    ${priceBundle} ${priceCurrency}
                </div>
        </div>
            <a class="lib-btn-secondary viewer-content-right-bundle-info__bth" href="/viewer/${base.getTypeNameByVal(data.type_item)}/${data.item_bundle_id}/">
                About
            </a>
    </div>
</div>`;
  }

  if (data.item_is_bundle == 1) {
    priceBundleItemElementEl.innerHTML = `<div class="viewer-content-right-base-container right-base-container-bundle-info">
    <div class="viewer-content-right-bundle-info">
        <div class="viewer-content-right-bundle-info__row">
                <div class="viewer-content-right-bundle-info__row__title">
                    Numbers item in bundle
                </div>
                <div class="viewer-content-right-bundle-info__row__text">
                    ${data.number_elements} 
                </div>
        </div>
            <a class="lib-btn-secondary viewer-content-right-bundle-info__bth" href="/viewer/${base.getTypeNameByVal(data.type_item)}/${data.item_bundle_id}/">
                About
            </a>
    </div>
</div>`;
  }
};

const handleGetItemMaterialElementsData = (data) => {
  itemElementsEl.innerHTML += `<div class="viewer-content-right-base-container-info material-element">
  <div class="viewer-content-right-element-material" >
      <div class="viewer-content-right-element-material-render">
          <div class="viewer-content-right-element-material-render__icon">
              <img src="${data.type_render_logo}">
          </div>
          <div class="viewer-content-right-element-material-render__text">Render:</div>
          <div class="viewer-content-right-element-material-render__export-type-render">${data.name_render}</div>
      </div>
      <div class="viewer-content-right-element-material-export">
          <div class="viewer-content-right-element-material-export__icon">
              <img src="${data.program_logo}">
          </div>
          <div class="viewer-content-right-element-material-export__text">
              ${data.program_name} version: ${data.version_name_program}
          </div>
          <!-- <div class="viewer-content-right-element-material-export__version"></div> -->

      </div>
      <div class="viewer-content-right-element-material-textures">
          <div class="viewer-content-right-element-material-textures__icon">
              <img src="/static/assets/icons/type_item/textures_type_icon_40x40.webp">
          </div>
          <div class="viewer-content-right-element-material-textures__text">Textures:</div>
          <div class="viewer-content-right-element-material-textures__number">${data.textures_number}</div>
      </div>
      <div class="viewer-content-right-element-material-shader">
          <div class="viewer-content-right-element-material-shader__icon">
              <img src="/static/assets/icons/type_item/shader_type_icon_40x40.webp">
          </div>
          <div class="viewer-content-right-element-material-shader__text">Shader:</div>
          <div class="viewer-content-right-element-material-shader__number">${data.scripts_number}</div>

      </div>
  </div>
  
</div>`;
};


const handleGetItemElementsData = (typeItem, itemId, numberElements, itemTypeView) => {
  $.ajax({
    type: 'GET',
    url: `/viewitem-list-item-elements-json/${typeItem}/${itemId}/${numberElements}/`,
    success: function (response) {
      const data = response.data;

      if (typeItem == 2) {
        handleGetItemModelsElementsData(data, itemTypeView);
      }
      else if (typeItem == 3) {
        handleGetItemTexturesElementsData(data, itemTypeView);
      }
      else if (typeItem == 5) {
        handleGetItemAddonsElementsData(data, itemTypeView);
      }
    },
    error: function (error) {
      console.log(error);
    }
  });
};

const handleGetItemModelsElementsData = (data, itemTypeView) => {

  data.map((item, index) => {

    let texturesStatusIcon = "";
    let materialStatusIcon = "";
    let shaderStatusIcon = "";
    let riggedStatusIcon = "";
    let animationStatusIcon = "";
    let uv_mapStatusIcon = "";
    let geometry_nodesStatusIcon = "";
    let optimised_for_smoosingStatusIcon = "";

    let texturesStatus = "";
    let materialStatus = "";
    let shaderStatus = "";
    let riggedStatus = "";
    let animationStatus = "";
    let uv_mapStatus = "";
    let geometry_nodesStatus = "";
    let optimised_for_smoosingStatus = "";

    let licensedTextElementEl = "";

    if (data.licensed_id == 0) {
      licensedTextElementEl = `<div class="viewer-content-right-element-models-center-accordion__licenset">
      <div class="viewer-content-right-element-models-center-accordion__licenset__title">
          Licenset
      </div>
      <div class="viewer-content-right-element-models-center-accordion__licenset__text">
      ${item.licensed_text}
      </div>
</div>`;
    }


    if (item.textures == 1) {
      texturesStatusIcon = `<img src="/static/assets/icons/type_item/textures_type_icon_40x40.webp">`;
      texturesStatus = `enabled`;
    }
    else {
      texturesStatusIcon = `<img src="/static/assets/icons/type_item/textures_type_icon_dis_40x40.webp">`;
      texturesStatus = `disabled`;
    }

    if (item.material == 1) {
      materialStatusIcon = `<img src="/static/assets/icons/type_item/type_material.webp">`;
      materialStatus = `enabled`;
    }
    else {
      materialStatusIcon = `<img src="/static/assets/icons/type_item/type_material_dis.webp">`;
      materialStatus = `disabled`;
    }

    if (item.shader == 1) {
      shaderStatusIcon = `<img src="/static/assets/icons/type_item/shader_type_icon_40x40.webp">`;
      shaderStatus = `enabled`;
    }
    else {
      shaderStatusIcon = `<img src="/static/assets/icons/type_item/shader_type_icon_dis_40x40.webp">`;
      shaderStatus = `disabled`;
    }
    if (item.rigged == 1) {
      riggedStatusIcon = `<img src="/static/assets/icons/type_item/rigged_type_icon_40x40.webp">`;
      riggedStatus = `enabled`;
    }
    else {
      riggedStatusIcon = `<img src="/static/assets/icons/type_item/rigged_type_icon_dis_40x40.webp">`;
      riggedStatus = `disabled`;
    }
    if (item.animation == 1) {
      animationStatusIcon = `<img src="/static/assets/icons/type_item/animation_type_40x40.webp">`;
      animationStatus = `enabled`;
    }
    else {
      animationStatusIcon = `<img src="/static/assets/icons/type_item/animation_type_dis_40x40.webp">`;
      animationStatus = `disabled`;
    }
    if (item.uv_map == 1) {
      uv_mapStatusIcon = `<img src="/static/assets/icons/type_item/uv_map_type_40x40.webp">`;
      uv_mapStatus = `enabled`;
    }
    else {
      uv_mapStatusIcon = `<img src="/static/assets/icons/type_item/uv_map_type_dis_40x40.webp">`;
      uv_mapStatus = `disabled`;
    }
    if (item.geometry_nodes == 1) {
      geometry_nodesStatusIcon = `<img src="/static/assets/icons/type_item/geometry_nodes_icon_40x40.webp">`;
      geometry_nodesStatus = `enabled`;
    }
    else {
      geometry_nodesStatusIcon = `<img src="/static/assets/icons/type_item/geometry_nodes_icon_dis_40x40.webp">`;
      geometry_nodesStatus = `disabled`;
    }
    if (item.optimised_for_smoosing == 1) {
      optimised_for_smoosingStatusIcon = `<img src="/static/assets/icons/type_item/optimised_for_smoosing__icon_40x40.webp">`;
      optimised_for_smoosingStatus = `enabled`;
    }
    else {
      optimised_for_smoosingStatusIcon = `<img src="/static/assets/icons/type_item/optimised_for_smoosing__icon_dis_40x40.webp">`;
      optimised_for_smoosingStatus = `disabled`;
    }


    let addToCartBotton = '';
    let addToCartBottonOpenAccordion = '';
    let classStyleHeight = '';

    if (itemTypeView == 1 || itemTypeView == 2) {
      addToCartBotton = `<div id="flush-collapse-model-${index}" class="accordion-collapse viewer-content-right-element-models-botton  collapse show">
      <div class="viewer-content-right-element-models-botton__price">${item.price} ${item.price_currency}</div>
          <a class="lib-btn-secondary viewer-content-right-element-models-botton__bth"
              href="libitems://libitems.com/viewer/${base.getTypeNameByVal(TYPE_ITEM)}/${ITEM_ID}?ide=${item.elements_id}&card=true">Add to cart</a>
  </div>`;

      addToCartBottonOpenAccordion = `<div class="viewer-content-right-element-models-botton show">
  <div class="viewer-content-right-element-models-botton__price">${item.price} ${item.price_currency}</div>
      <a class="lib-btn-secondary viewer-content-right-element-models-botton__bth"
          href="libitems://libitems.com/viewer/${base.getTypeNameByVal(TYPE_ITEM)}/${ITEM_ID}?ide=${item.elements_id}&card=true">Add to cart</a>
</div>`;
    }
    else {
      classStyleHeight = 'element-height-not-add-to-cart-button';
    }


    itemElementsEl.innerHTML += `<div class="viewer-content-right-base-container-element ${classStyleHeight}">
    <div class="viewer-content-right-element-models">
        <div class="accordion accordion-flush" aria-multiselectable="true" id="accordionFlush-model-${index}">
              <div class="accordion-header" id="flush-heading-model-${index}">
                <div role="button" class="collapsed accordion-item-element" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-model-${index}" aria-expanded="false" aria-controls="flush-collapse-model-${index}">
                    <div class="viewer-content-right-element-models-top">
                        <div class="viewer-content-right-element-models-top__row-open">
                            <svg xmlns="http://www.w3.org/2000/svg" class="open-accordion lib-icon lib-icon-size-18 lib-icon-no-fill" fill="#ff0000" stroke-width="2" height="18px" viewBox="0 0 24 24" width="18px">
                                <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>                            
                        </div>
                        <div class="viewer-content-right-element-models-top__row-format">${item.file_extension}</div>
                        <div class="viewer-content-right-element-models-top__row-polygons">Polygons: ${item.polygon}</div>

                    </div>
                    <div id="flush-collapse-model-${index}" class="accordion-collapse viewer-content-right-element-models-center collapse show">
                    <div class="viewer-content-right-element-models-center__icon">
                      ${materialStatusIcon}
                    </div>
                    <div class="viewer-content-right-element-models-center__icon">
                      ${texturesStatusIcon}
                    </div>
                    <div class="viewer-content-right-element-models-center__icon">
                      ${shaderStatusIcon}
                    </div>
                    <div class="viewer-content-right-element-models-center__icon">
                      ${uv_mapStatusIcon}
                    </div>
                    <div class="viewer-content-right-element-models-center__icon">
                      ${animationStatusIcon}
                    </div>
                    <div class="viewer-content-right-element-models-center__icon">
                      ${riggedStatusIcon}
                    </div>
                    <div class="viewer-content-right-element-models-center__icon">
                      ${geometry_nodesStatusIcon}
                    </div>
                </div>
              ${addToCartBotton}
                </div>
            </div>
              <div id="flush-collapse-model-${index}" class="accordion-collapse collapse" aria-labelledby="flush-heading-model-${index}" data-bs-parent="#accordionFlush-model-${index}">
                    <div class="viewer-content-right-element-models-center-accordion">
                        <div class="viewer-content-right-element-models-center-accordion__row">
                            <div class="viewer-content-right-element-models-center-accordion__row__icon">
                                <img itemprop="image" src="${item.icon_program}">
                            </div>
                            <div class="viewer-content-right-element-models-center-accordion__row__name">
                                ${item.name_program}
                            </div>
                            <div class="viewer-content-right-element-models-center-accordion__row__version">
                                ${item.version_program}
                            </div>
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion">
                        <div class="viewer-content-right-element-models-center-accordion__row">
                            <div class="viewer-content-right-element-models-center-accordion__row__icon">
                                
                            </div>
                            <div class="viewer-content-right-element-models-center-accordion__row__name">
                                Size:
                            </div>
                            <div class="viewer-content-right-element-models-center-accordion__row__size">
                                ${item.size}
                            </div>
                        </div>
                    <div class="viewer-content-right-element-models-center-accordion__row">
                        <div class="viewer-content-right-element-models-center-accordion__row__icon">
                        ${optimised_for_smoosingStatusIcon}
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__name">
                            Optimized for smoothing
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__status">
                            <div class="viewer-content-right-element-models-center-accordion__row__status__${optimised_for_smoosingStatus}"></div>
                        </div>
                    </div>
                    <div class="viewer-content-right-element-models-center-accordion__row">
                        <div class="viewer-content-right-element-models-center-accordion__row__icon">
                        ${materialStatusIcon}
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__name">
                            Material
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__status">
                            <div class="viewer-content-right-element-models-center-accordion__row__status__${materialStatus}"></div>
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__name">
                        ${item.name_render}
                    </div>
                    </div>
                    <div class="viewer-content-right-element-models-center-accordion__row">
                        <div class="viewer-content-right-element-models-center-accordion__row__icon">
                        ${texturesStatusIcon}
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__name">
                            Textures
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__status">
                            <div class="viewer-content-right-element-models-center-accordion__row__status__${texturesStatus}"></div>
                        </div>
                    </div>
                    <div class="viewer-content-right-element-models-center-accordion__row">
                        <div class="viewer-content-right-element-models-center-accordion__row__icon">
                        ${shaderStatusIcon}
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__name">
                            Shader
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__status">
                            <div class="viewer-content-right-element-models-center-accordion__row__status__${shaderStatus}"></div>
                        </div>
                    </div>
                    <div class="viewer-content-right-element-models-center-accordion__row">
                        <div class="viewer-content-right-element-models-center-accordion__row__icon">
                        ${uv_mapStatusIcon}
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__name">
                            UV maps
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__status">
                            <div class="viewer-content-right-element-models-center-accordion__row__status__${uv_mapStatus}"></div>
                        </div>
                    </div>
                    <div class="viewer-content-right-element-models-center-accordion__row">
                        <div class="viewer-content-right-element-models-center-accordion__row__icon">
                        ${animationStatusIcon}
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__name">
                            Animation
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__status">
                            <div class="viewer-content-right-element-models-center-accordion__row__status__${animationStatus}"></div>
                        </div>
                    </div>
                    <div class="viewer-content-right-element-models-center-accordion__row">
                        <div class="viewer-content-right-element-models-center-accordion__row__icon">
                        ${riggedStatusIcon}
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__name">
                            Rigged
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__status">
                            <div class="viewer-content-right-element-models-center-accordion__row__status__${riggedStatus}"></div>
                        </div>
                    </div>
                    <div class="viewer-content-right-element-models-center-accordion__row">
                        <div class="viewer-content-right-element-models-center-accordion__row__icon">
                        ${geometry_nodesStatusIcon}
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__name">
                            Geometry nodes
                        </div>
                        <div class="viewer-content-right-element-models-center-accordion__row__status">
                            <div class="viewer-content-right-element-models-center-accordion__row__status__${geometry_nodesStatus}"></div>
                        </div>
                    </div>
                    ${licensedTextElementEl}
                    ${addToCartBottonOpenAccordion}
                    </div>
              </div>
            </div>
    </div>
</div>`;

  });
};

const handleGetItemTexturesElementsData = (data, itemTypeView) => {
  data.map((item, index) => {


    let addToCartBotton = '';
    let addToCartBottonOpenAccordion = '';
    let classStyleHeight = '';
    let licensedTextElementEl = "";

    if (data.licensed_id == 0) {
      licensedTextElementEl = `<div class="viewer-content-right-element-models-center-accordion__licenset">
      <div class="viewer-content-right-element-models-center-accordion__licenset__title">
          Licenset
      </div>
      <div class="viewer-content-right-element-models-center-accordion__licenset__text">
      ${item.licensed_text}
      </div>
</div>`;
    }

    if (itemTypeView == 1 || itemTypeView == 2) {
      addToCartBotton = `<div id="flush-collapse-${index}" class="accordion-collapse viewer-content-right-element-textures-botton  collapse show">
      <div class="viewer-content-right-element-textures-botton__price">${item.price} ${item.price_currency}</div>
          <a class="lib-btn-secondary viewer-content-right-element-textures-botton__bth"
              href="libitems://libitems.com/viewer/${base.getTypeNameByVal(TYPE_ITEM)}/${ITEM_ID}?ide=${item.elements_id}&card=true">Add to cart</a>
  </div>`;

      addToCartBottonOpenAccordion = `<div class="viewer-content-right-element-textures-botton show">
  <div class="viewer-content-right-element-textures-botton__price">${item.price} ${item.price_currency}</div>
      <a class="lib-btn-secondary viewer-content-right-element-textures-botton__bth"
          href="libitems://libitems.com/viewer/${base.getTypeNameByVal(TYPE_ITEM)}/${ITEM_ID}?ide=${item.elements_id}&card=true">Add to cart</a>
</div>`;
    }
    else {
      classStyleHeight = 'element-height-not-add-to-cart-button';
    }

    var seamlessMap = '';

    if (item.tile_map == 0) {
      seamlessMap = 'disabled';
    }
    else if (item.tile_map == 1) {
      seamlessMap = 'enabled';
    }

    let listMapHtml = '';
    const mapArray = item.type_map_list.split(",");
    mapArray.map(itemMap => {
      listMapHtml += `<div class="viewer-content-right-element-textures-center-accordion__row-maps">
      <div class="viewer-content-right-element-textures-center-accordion__row-maps__name">
          ${itemMap}
      </div>
  </div>`;

    });


    itemElementsEl.innerHTML += `<div class="viewer-content-right-base-container-element ${classStyleHeight}">
    <div class="viewer-content-right-element-textures">
        <div class="accordion accordion-flush" aria-multiselectable="true" id="accordionFlush-${index}">
              <div class="accordion-header" id="flush-heading-${index}">
                <div role="button" class="collapsed accordion-item-element" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-${index}" aria-expanded="false" aria-controls="flush-collapse-${index}">
                    <div class="viewer-content-right-element-textures-top">
                        <div class="viewer-content-right-element-textures-top__row-open">
                            <svg xmlns="http://www.w3.org/2000/svg" class="open-accordion lib-icon lib-icon-size-18 lib-icon-no-fill" fill="#ff0000" stroke-width="2" height="18px" viewBox="0 0 24 24" width="18px">
                                <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                        <div class="viewer-content-right-element-textures-top__row-format">${item.file_extension} ${item.widht}x${item.height} px</div>

                    </div>
                    <div class="viewer-content-right-element-textures-center">
                        <div class="viewer-content-right-element-textures-center__maps">${item.number_map} maps</div>
                        <div class="viewer-content-right-element-textures-center__size">${item.size}</div>
                        </div>
                      ${addToCartBotton}
                </div>
            </div>
              <div id="flush-collapse-${index}" class="accordion-collapse collapse" aria-labelledby="flush-heading-${index}" data-bs-parent="#accordionFlush-${index}">
                    <div class="viewer-content-right-element-textures-center-accordion">

                    <div class="viewer-content-right-element-textures-center-accordion__row">

                        <div class="viewer-content-right-element-textures-center-accordion__row__name">
                            Seamless map
                        </div>
                        <div class="viewer-content-right-element-textures-center-accordion__row__status">
                            <div class="viewer-content-right-element-textures-center-accordion__row__status__${seamlessMap}"></div>
                        </div>
                    </div>
                    ${listMapHtml}
                    ${licensedTextElementEl}
                    ${addToCartBottonOpenAccordion}
                    </div>
              </div>
            </div>
    </div>
</div>`;
  });
};

const handleGetItemAddonsElementsData = (data, itemTypeView) => {
  data.map((item, index) => {
    let typePublicName = '';
    let typePublicIcon = '';
    const supportIconEnabled = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <g clip-path="url(#clip0_455_2688)">
                                    <path d="M9.99999 15.172L19.192 5.979L20.607 7.393L9.99999 18L3.63599 11.636L5.04999 10.222L9.99999 15.172Z" fill="#00B94A"/>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_455_2688">
                                      <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                  </defs>
                                </svg>`;
      const supportIconDisabled = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                     <g clip-path="url(#clip0_455_2692)">
                                       <path d="M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z" fill="#A9B6C3"/>
                                     </g>
                                     <defs>
                                       <clipPath id="clip0_455_2692">
                                         <rect width="24" height="24" fill="white"/>
                                       </clipPath>
                                     </defs>
                                   </svg>`;
    let supportWindowsStatus = '';
    let supportLinuxStatus = '';
    let supportMacStatus = '';
    let sizeWindows = '';
    let sizeLinux = '';
    let sizeMac = '';
    if (item.type_public == 3) {
      typePublicName = 'ALPHA';
      typePublicIcon = 'alpha';
    }
    else if (item.type_public == 2) {
      typePublicName = 'BETA';
      typePublicIcon = 'beta';
    }
    else if (item.type_public == 1) {
      typePublicName = 'RELEASE';
      typePublicIcon = 'release';
    }


    if (item.support_windows == 1) {
      supportWindowsStatus = 'enabled';
      sizeWindows = item.size_windows;
    }
    else if (item.support_windows == 0) {
      supportWindowsStatus = 'disabled';
    }

    if (item.support_linux == 1) {
      supportLinuxStatus = 'enabled';
      sizeLinux = item.size_linux;
    }
    else if (item.support_linux == 0) {
      supportLinuxStatus = 'disabled';
    }

    if (item.support_mac == 1) {
      supportMacStatus = 'enabled';
      sizeMac = item.size_mac;
    }
    else if (item.support_mac == 0) {
      supportMacStatus = 'disabled';
    }


    let versionProgram = '';
    if (item.min_version == item.max_version) {
      versionProgram = item.min_version;
    }
    else if (item.min_version != item.max_version && item.max_version != '') {
      versionProgram = item.min_version + " ‒ " + item.max_version;
    }
    else if (item.max_version == '') {
      versionProgram = item.min_version + " +";
    }


    let addToCartBotton = '';
    let addToCartBottonOpenAccordion = '';
    let classStyleHeight = '';


    if (itemTypeView == 1 || itemTypeView == 2) {
      addToCartBotton = `<div id="flush-collapse-${index}" class="accordion-collapse viewer-content-right-element-addons-botton  collapse show">
      <div class="viewer-content-right-element-addons-botton__price">${item.price} ${item.price_currency}</div>
          <a class="lib-btn-secondary viewer-content-right-element-addons-botton__bth"
              href="libitems://libitems.com/viewer/${base.getTypeNameByVal(TYPE_ITEM)}/${ITEM_ID}?ide=${item.elements_id}&card=true">Add to cart</a>
  </div>`;

      addToCartBottonOpenAccordion = `<div class="viewer-content-right-element-addons-botton show">
  <div class="viewer-content-right-element-addons-botton__price">${item.price} ${item.price_currency}</div>
      <a class="lib-btn-secondary viewer-content-right-element-addons-botton__bth"
          href="libitems://libitems.com/viewer/${base.getTypeNameByVal(TYPE_ITEM)}/${ITEM_ID}?ide=${item.elements_id}&card=true">Add to cart</a>
</div>`;
    }
    else {
      classStyleHeight = 'element-height-not-add-to-cart-button';
    }

    itemElementsEl.innerHTML += `<div class="viewer-content-right-base-container-element ${classStyleHeight}">
    <div class="viewer-content-right-element-addons">
        <div class="accordion accordion-flush" aria-multiselectable="true" id="accordionFlush-${index}">
              <div class="accordion-header" id="flush-heading-${index}">
                <div role="button" class="collapsed accordion-item-element" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-${index}" aria-expanded="false" aria-controls="flush-collapse-${index}">
                    <div class="viewer-content-right-element-addons-top">
                        <div class="viewer-content-right-element-addons-top__row-open">
                            <svg xmlns="http://www.w3.org/2000/svg" class="open-accordion lib-icon lib-icon-size-18 lib-icon-no-fill" fill="#ff0000" stroke-width="2" height="18px" viewBox="0 0 24 24" width="18px">
                                <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                        <div class="viewer-content-right-element-addons-top__row-program ">
                          <img src="${item.icon_program}">
                        </div>
                        <div class="viewer-content-right-element-addons-top__row-format">
                            ${versionProgram}</div>
                        <div class="viewer-content-right-element-addons-top__row-os">
                            <div class="viewer-content-right-element-addons-top__row-os__icon windows ${supportWindowsStatus}">
                            <img src="/static/assets/icons/type_os/windows2.webp">
                            </div>
                            <div class="viewer-content-right-element-addons-top__row-os__icon ${supportMacStatus}">
                            <img src="/static/assets/icons/type_os/mac2.webp">
                            </div>
                            <div class="viewer-content-right-element-addons-top__row-os__icon ${supportLinuxStatus}">
                            <img src="/static/assets/icons/type_os/linux2.webp">
                            </div>
                        </div>

                    </div>
                    <div class="viewer-content-right-element-addons-center">
                        <div class="viewer-content-right-element-addons-center-icon ${typePublicIcon}">${typePublicName}</div>
                        <div class="viewer-content-right-element-addons-center-version">${item.version_number_user}</div>
                    </div>
                  ${addToCartBotton}
                </div>
            </div>
              <div id="flush-collapse-${index}" class="accordion-collapse collapse" aria-labelledby="flush-heading-${index}" data-bs-parent="#accordionFlush-${index}">
                    <div class="viewer-content-right-element-addons-center-accordion">

                    <div class="viewer-content-right-element-addons-center-accordion__row">
                        <div class="viewer-content-right-element-addons-center-accordion__row__icon ${supportWindowsStatus}">
                          <img src="/static/assets/icons/type_os/windows.webp" />
                        </div>
                        <div class="viewer-content-right-element-addons-center-accordion__row__name">
                            Windows
                        </div>
                        <div class="viewer-content-right-element-addons-center-accordion__row__status">
                            <div class="viewer-content-right-element-addons-center-accordion__row__status">
                              ${supportWindowsStatus == 'enabled' ? supportIconEnabled : supportWindowsStatus == 'disabled' ? supportIconDisabled : ''}
                            </div>
                        </div>
                        <div class="viewer-content-right-element-addons-center-accordion__row__size">${sizeWindows}</div>
                    </div>
                    <div class="viewer-content-right-element-addons-center-accordion__row">
                        <div class="viewer-content-right-element-addons-center-accordion__row__icon ${supportMacStatus}">
                          <img src="/static/assets/icons/type_os/mac.webp" />
                        </div>
                        <div class="viewer-content-right-element-addons-center-accordion__row__name">
                            Mac
                        </div>
                        <div class="viewer-content-right-element-addons-center-accordion__row__status">
                            <div class="viewer-content-right-element-addons-center-accordion__row__status">
                              ${supportMacStatus == 'enabled' ? supportIconEnabled : supportMacStatus == 'disabled' ? supportIconDisabled : ''}
                            </div>
                        </div>
                        <div class="viewer-content-right-element-addons-center-accordion__row__size">${sizeMac}</div>
                    </div>
                    <div class="viewer-content-right-element-addons-center-accordion__row">
                        <div class="viewer-content-right-element-addons-center-accordion__row__icon ${supportLinuxStatus}">
                          <img src="/static/assets/icons/type_os/Tux.webp" />
                        </div>
                        <div class="viewer-content-right-element-addons-center-accordion__row__name">
                            Linux
                        </div>
                        <div class="viewer-content-right-element-addons-center-accordion__row__status">
                            <div class="viewer-content-right-element-addons-center-accordion__row__status">
                              ${supportLinuxStatus == 'enabled' ? supportIconEnabled : supportLinuxStatus == 'disabled' ? supportIconDisabled : ''}
                            </div>
                        </div>
                        <div class="viewer-content-right-element-addons-center-accordion__row__size">${sizeLinux}</div>
                    </div>
                    <div class="viewer-content-right-element-addons-center-accordion__licenset">
                                <div class="viewer-content-right-element-addons-center-accordion__licenset__title">
                                    Licenset
                                </div>
                                <div class="viewer-content-right-element-addons-center-accordion__licenset__text">
                                ${item.licensed_text}
                                </div>
                    </div>
                    ${addToCartBottonOpenAccordion}
                    </div>
            </div>
          </div>
    </div>
</div>`;
  });
};

const handleGetItemBundleElementsData = (typeItem, itemId, numberElements) => {
  $.ajax({
    type: 'GET',
    url: `/viewitem-list-item-bundle-json/${typeItem}/${itemId}/${numberElements}/`,
    success: function (response) {
      const data = response.data;

      data.map(item => {
        bundleListItemsEl.innerHTML += `<a href="/viewer/${base.getTypeNameByVal(item.type_item)}/${item.item_id}/" class="viewer-bundle-group-element">
        <img src="${item.root_preview_small}" />
      </a> `;

      });

    },
    error: function (error) {
      console.log(error);
    }
  });
};

const canBePurchasedAsRealObjectData = (data) => {
  if (data.type_item == 2) {
    if (data.has_physical_prototype == 1) {
      let priceRealObject = '';
      let ean = '';

      if (data.physical_prototype_price != 0) {
        priceRealObject = `<div class="viewer-content-right-real-object__botton__price">${data.physical_prototype_price}</div>`;
      }

      if (data.physical_prototype_ean != '') {
        ean = 'EAN ' + data.physical_prototype_ean;
      }


      canBePurchasedAsRealObjectEl.innerHTML = `<div class="viewer-content-right-base-container can-be-purchased-as-real-object">
      <div class="viewer-content-right-real-object">
          <div class="viewer-content-right-real-object__title">
              Can be purchased as real object
          </div>
          <div class="viewer-content-right-real-object__text">
              ${ean}
          </div>
          <div class="viewer-content-right-real-object__botton">
              ${priceRealObject}
              <div class="viewer-content-right-real-object__botton__bth">
                  <a class="lib-btn-primary viewer-content-right-real-object__botton__bth" href="${data.physical_prototype_url}">
                      Buy
                  </a>
              </div>
          </div>
      </div>
    </div>`;
    }
  }
};

const setSociaShareElement = (typeItem, itemId, nameItem, previewItem, userName)  => {
  
  let sharedLink = encodeURIComponent(`${location.protocol}//${location.host}/viewer/${base.getTypeNameByVal(typeItem)}/${itemId}/`);
  nameItem = encodeURIComponent(nameItem);
  previewItem = encodeURIComponent(previewItem);
  userName = encodeURIComponent(userName);

  let Facebook = `https://www.facebook.com/sharer/sharer.php?u=${sharedLink}`;
  let Twitter = `https://twitter.com/intent/tweet?text=${nameItem}&url=${sharedLink}&via=Libitems`;
  let Linkedin = `https://www.linkedin.com/shareArticle?mini=true&url=${sharedLink}&title=${nameItem}`;  
  let Pinterest = `https://pinterest.com/pin/create/button/?url=${sharedLink}&media=${previewItem}&description=${nameItem}. This item from Library Items for Blender, added by user: ${userName}`;
  let LibitemsApp = `libitems://libitems.com/viewer/${base.getTypeNameByVal(typeItem)}/${itemId}/?view=true`;

  setSociaShareElementProperties(viewerItemSocialNetworkPinterestEL, Pinterest);
  setSociaShareElementProperties(viewerItemSocialNetworkFacebookEL, Facebook);
  setSociaShareElementProperties(viewerItemSocialNetworkTwitterEL, Twitter);
  setSociaShareElementProperties(viewerItemSocialNetworkLinkedinEL, Linkedin);
  setSociaShareElementProperties(viewerItemSocialNetworkLibitemsAppEL, LibitemsApp);  
  setSociaShareElementProperties(viewerItemSocialNetworkInstagramEL, '');
};

const setSociaShareElementProperties = (element, value) => {
  if (!element) {
    return;
  }

  let aEl = element.getElementsByTagName("a")[0];
  if (element && value && value != '') {
    aEl.setAttribute("href", value);
  }
  else {
    element.setAttribute("style", "display: none;");
  }
};

export { initializeViewerPage };