import $ from "jquery/dist/jquery";
import * as comments from "./comments";
import * as base from "./base";

const widthContainerEl = document.getElementById('width-container');

const articleContentContainerEl = document.getElementById('article-content-container');
const itemTagEl = document.getElementById('item-tag');

const commentsListArticleEl = document.getElementById('comments-list-article');

let TYPE_ARTICLE_ID = 0;
var USER_ID = 0;
let CHANGELOG_ID = 0;

var manualIdFirstOpen = 0;
var currentViewManualId = 0;



function initializeDocumentationViewerPage() {
    widthContainerEl.classList.add('article-manual-width');

    let url = location.href;
    if (location.hash) {
      const hash = url.split("#");
      manualIdFirstOpen = hash[1];
    }

    TYPE_ARTICLE_ID = 22;

  handleGetArticleData();
}

const handleGetArticleData = () => {
  $.ajax({
    type: 'GET',
    url: `/documentation-base-json/`,
    success: function (response) {
      const data = response.data;
      USER_ID = data.user_id;

        articleContentContainerEl.innerHTML = `<div class="article-manual-content"><div class="article-content-left">
            <div class="manual-item-left-container" id="article-manual-tree-list-item"></div>
        </div>
            <div class="article-manual-item-text-container">
            <div class="article-manual-item-title" id="manual-item-title"></div>
            <div class="article-manual-item-text" id="manual-item-text"></div>
        </div>
        </div>`;

      handleGetItemManualTreeData(data);

      if (data.number_comments != 0) {
        comments.handleGetItemCommentsData(commentsListArticleEl, TYPE_ARTICLE_ID, CHANGELOG_ID, USER_ID);
      }

    },
    error: function (error) {
      console.log(error);
    }
  });
};


const handleGetItemManualTreeData = (data) => {
      const manualTreeListEl = document.getElementById('article-manual-tree-list-item');
      var htmlTree = `<div class="lib-tree-level"><span class="indent"></span><ul class="lib-tree-items-list">`;

      data.map(item => {

        if (item.root_id == 0 && item.sequence_number == 0 && manualIdFirstOpen == 0) {
          manualIdFirstOpen = item.item_id;
        }

        if (item.root_id == 0) {
          if (item.number_children == 0) {
            htmlTree += `<li><a href="#${item.item_id}" class="lib-tree-item" data-value="${item.item_id}">${item.name_item}<span class="lib-tree-item__count"></span></a></li>`;
          }
          else {
            htmlTree += `<a href="#${item.item_id}" class="lib-tree-item lib-tree-item__top" data-bs-toggle="collapse" type="button" role="button" 
                         data-bs-target="#tree-${item.item_id}-collapse" aria-expanded="true" data-value="${item.item_id}"> ${item.name_item} <span class="lib-tree-item__count"></span>
                             <div class="lib-tree-list-open">
                             <svg xmlns="http://www.w3.org/2000/svg" class="lib-icon lib-icon-size-18 lib-icon-no-fill" fill="#ff0000" stroke-width="2" height="18px" viewBox="0 0 24 24" width="18px">
                             <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                         </svg>
                              </div>
                              <div class="lib-tree-list-close">
                              <svg xmlns="http://www.w3.org/2000/svg" class="lib-icon lib-icon-size-18 lib-icon-no-fill" fill="#ff0000" stroke-width="2" height="18px" viewBox="0 0 24 24" width="18px">
                                     <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                 </svg>
                                  
                              </div>
                          </a>
                          <div class="lib-tree-level collapse show" id="tree-${item.item_id}-collapse">
                          <span class="indent"></span><ul class="lib-tree-items-list">`;

            htmlTree += manualTreeBuild(item.item_id, data);
            htmlTree += `</ul></div>`;
          }
        }
      });

      htmlTree += `</ul></div>`;
      manualTreeListEl.innerHTML = htmlTree;

      handleGetItemManualArticleData(manualIdFirstOpen);

      const manualArticleList = [].slice.call(document.querySelectorAll('a[data-value]'));
      
      manualArticleList.forEach(function (manualArticleListItem) {
        manualArticleListItem.addEventListener("click", function (e) {
          let idManual = e.target.href;
          if (idManual == undefined) return;
          
          const hash = idManual.split("#");
          //це потріно що якщо хтось клікає в дереві туж позицію не перевантажувались дані з сервера
          if (currentViewManualId != hash[1]) {
            currentViewManualId = hash[1];
            handleGetItemManualArticleData(hash[1]);
          }
        });
      });
};

function manualTreeBuild(rootId, dataTree) {
  var htmlTree = ``;
  console.log('test tree: ');
  console.log(rootId);
  dataTree.map(item => {

    if (item.root_id == rootId) {
      if (item.number_children == 0) {
        htmlTree += `<li><a href="#${item.item_id}" class="lib-tree-item" data-value="${item.item_id}">${item.name_item}<span class="lib-tree-item__count"></span></a></li>`;
      }
      else {
        htmlTree += `<a href="#${item.item_id}" class="lib-tree-item lib-tree-item__top" data-bs-toggle="collapse" type="button" role="button"
                     data-bs-target="#tree-${item.item_id}-collapse" aria-expanded="true" data-value="${item.item_id}"> ${item.name_item} <span class="lib-tree-item__count"></span>
                         <div class="lib-tree-list-open">
                         <svg xmlns="http://www.w3.org/2000/svg" class="lib-icon lib-icon-size-18 lib-icon-no-fill" fill="#ff0000" stroke-width="2" height="18px" viewBox="0 0 24 24" width="18px">
                         <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                     </svg>
                         </div>
                         <div class="lib-tree-list-close" >
                         <svg xmlns="http://www.w3.org/2000/svg" class="lib-icon lib-icon-size-18 lib-icon-no-fill" fill="#ff0000" stroke-width="2" height="18px" viewBox="0 0 24 24" width="18px">
                         <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                     </svg>
                             
                         </div>
                     </a>
                     <div class="lib-tree-level collapse show" id="tree-${item.item_id}-collapse">
                     <span class="indent"></span><ul class="lib-tree-items-list">`;

        htmlTree += manualTreeBuild(item.item_id, dataTree);
        htmlTree += `</ul></div>`;
      }
    }
  });
  return htmlTree;
}

const handleGetItemManualArticleData = (itemId) => {
  $.ajax({
    type: 'GET',
    url: `/documentation-article-json/${itemId}/`,
    success: function (response) {
      const data = response.data;
      
      const manualTextTitleEl = document.getElementById('manual-item-title');
      if (manualTextTitleEl != undefined) {
        manualTextTitleEl.innerHTML = data.title_article;
      }
      const manualTextEl = document.getElementById('manual-item-text');
      if (manualTextEl != undefined) {
        manualTextEl.innerHTML = data.description;
      }
      
      base.tagListItem(itemTagEl, data.type_item_article, data.tag_item);

    },
    error: function (error) {
      console.log(error);
    }
  });
};

export { initializeDocumentationViewerPage };