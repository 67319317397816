import $ from "jquery/dist/jquery";
import * as base from "./base";
const hhowtouseContentEl = document.getElementById('howtouse-body');

function initializeHowToUsePage() {
    handleGetHowToUseItems();
}

const handleGetHowToUseItems = () => {
    $.ajax({
      type: 'GET',
      url: `/how-to-use-json/`,
      success: function (response) {
        const data = response.data;
        hhowtouseContentEl.innerHTML = '';
        data.map(item => {
            hhowtouseContentEl.innerHTML += base.howToUseItems(item, true, null, false);
          });
      },
      error: function (error) {
        console.log(error);
      }
    });
  };

export { initializeHowToUsePage };