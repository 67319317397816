const htuDescriptionContentEl = document.getElementById('htu-description-content');
const htuWrapperEl = document.getElementsByClassName('howtouse-wrapper');
const urlParams = { id: "", type: "", style: ""};

function initializeHtuDescriptionPage() {
  handleGetHowToUseItems();
}

const handleGetHowToUseItems = () => {
  parseUrlParams();
  if (urlParams.id === "" || urlParams.type === "") {
    console.warn(`Id or type is empty`);
    return;
  }
  
  if (htuWrapperEl != undefined && htuDescriptionContentEl != undefined) {
    htuWrapperEl[0].classList.add('block-full-height');
    htuDescriptionContentEl.classList.add('block-full-height');
  }

  if (urlParams.style === 'black' && htuWrapperEl != undefined) {
    changeBackgroundToBlack();
  }
};

const parseUrlParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (params?.id != undefined) {
    urlParams.id = params.id;
  }
  if (params?.type != undefined) {
    urlParams.type = params.type;
  }
  if (params?.style != undefined) {
    urlParams.style = params.style;
  }

};

const changeBackgroundToBlack = () => {
  htuWrapperEl[0].style.background  = "#1d1d1d";

  walkThoughChilds(htuWrapperEl[0]);
};

const walkThoughChilds = (el) => {
  changeColorToWhite(el);
  Array.from(el.children).forEach(walkThoughChilds);
};

const changeColorToWhite = (child) => {
  if (child.style != undefined) {
    if (child.style.color != undefined) {
      child.style.color = 'white';
    }
  }
};

export {
  initializeHtuDescriptionPage
};