import $ from "jquery/dist/jquery";
import * as comments from "./comments";
import * as base from "./base";
import { ItemTypePair } from "./base";

const widthContainerBodyEl = document.getElementById('width-container-body');
const widthContainerEl = document.getElementById('width-container');

const rootPreviewBigEl = document.getElementById('libitems-article-preview-img');

const articleContentContainerEl = document.getElementById('article-content-container');

const articleDateLastUpdateEl = document.getElementById('article-date-last-update');
const articleTitleNameEl = document.getElementById('article-title-name');

const articleUserAvatarImgEl = document.getElementById('user-avatar-img');
const articleUserNameEl = document.getElementById('user-name');
const itemTagEl = document.getElementById('item-tag');

const itemCommentsTypeEl = document.getElementById('item-comments-type');
const commentsListArticleEl = document.getElementById('comments-list-article');

const howToUseContentContainerEl = document.getElementById('howtouse-list-item');


let TYPE_ARTICLE = 0;
let TYPE_ARTICLE_ID = 0;
let TYPE_ITEM = `None`;
let ITEM_ID = 0;
var USER_ID = 0;
let CHANGELOG_ID = 0;
var viewListTree = 0;
var manualTextEl = 0;
var manualTextTitleEl = 0;
var manualIdFirstOpen = 0;
var currentViewManualId = 0;


function initializeArticlieViewerPage() {
  console.log('viewer page has been initialized');
  TYPE_ARTICLE = getTypeArticleFromUrl(location.pathname);
  const idTypePair = getItemTypePairFromUrl(location.pathname);

  if (TYPE_ARTICLE == 'manual') {
    TYPE_ITEM = idTypePair.type;
    ITEM_ID = idTypePair.id;
    TYPE_ARTICLE_ID = 23;
    widthContainerBodyEl.classList.add('article-manual-width');
    widthContainerEl.classList.add('article-manual-width');

    let url = location.href;
    if (location.hash) {
      const hash = url.split("#");
      manualIdFirstOpen = hash[1];
    }
  }
  else if (TYPE_ARTICLE == 'how-to-use') {
    TYPE_ITEM = idTypePair.type;
    ITEM_ID = idTypePair.id;
    TYPE_ARTICLE_ID = 26;
    widthContainerBodyEl.classList.add('article-base-width');
  }
  else if (TYPE_ARTICLE == 'changelog') {
    CHANGELOG_ID = getChangelogIdFromUrl(location.pathname);
    TYPE_ITEM = idTypePair.type;
    ITEM_ID = idTypePair.id;
    TYPE_ARTICLE_ID = 26;
    widthContainerBodyEl.classList.add('article-base-width');
    widthContainerEl.classList.add('article-base-width');
  }
  else {
    TYPE_ITEM = idTypePair.type;
    ITEM_ID = idTypePair.id;    
    TYPE_ARTICLE_ID = 21;
    widthContainerBodyEl.classList.add('article-base-width');
    widthContainerEl.classList.add('article-base-width');
  }

  handleGetArticleData(TYPE_ARTICLE, TYPE_ITEM, ITEM_ID);
}
const getTypeArticleFromUrl = (url) => {
  const regex = /\/(blog|changelog|manual|how-to-use)\//;
  const match = url.match(regex);
  if (match) {
    return match[1];
  }
  console.error(`Error: Unable to parse URL in getTypeArticleFromUrl function`);
  return null;
};

const getItemTypePairFromUrl = (url) => {
  const regex = /\/(blog|changelog|manual|how-to-use)\/(\w+)\/(\d+)\//;
  const regexBlog = /\/(blog)\/(\d+)\//;
  const regexHowToUseLibitems = /\/(how-to-use)\/(\d+)\//;

  const match = url.match(regex);
  const matchBlog = url.match(regexBlog);
  const matchHowToUseLibitems = url.match(regexHowToUseLibitems);
  if (matchBlog) {
    const idTypePair = new ItemTypePair(matchBlog[1], matchBlog[2]);
    return idTypePair;
  }
  else if (matchHowToUseLibitems) {
    const idTypePair = new ItemTypePair('NoneType', matchHowToUseLibitems[2]);
    return idTypePair;
  }
  else if (match) {
    const idTypePair = new ItemTypePair(match[2], match[3]);
    return idTypePair;
  }

  console.error(`Error: Unable to parse URL in getItemTypePairFromUrl function`);
  return null;
};

const getChangelogIdFromUrl = (url) => {  
  const regex = /\/(blog|changelog|manual|how-to-use)\/(\w+)\/(\d+)\/(\d+)\//;
  const match = url.match(regex);
  if (match) {
    return match[4];
  }  
  console.error(`Error: Unable to parse URL in getChangelogIdFromUrl function`);
  return null;
};

const handleGetArticleData = (typeArticle, typeItem, itemId) => {
  $.ajax({
    type: 'GET',
    url: `/article-base-json/${typeArticle}/${typeItem}/${itemId}/${CHANGELOG_ID ? `${CHANGELOG_ID}` : '0'}/`,
    success: function (response) {
      const data = response.data;

      USER_ID = data.user_id;
      
      if (rootPreviewBigEl && data.root_preview && data.root_preview != '') {
        let previewImg = document.createElement("img");
        previewImg.setAttribute("src", data.root_preview);
        previewImg.setAttribute("class", "preview-img");
        rootPreviewBigEl.append(previewImg);
      }
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      var optionsDate = {
        timeZone: timezone,
        timeStyle: "short",
        dateStyle: "short"
      };
      articleDateLastUpdateEl.innerHTML = 'last updated: ' + new Intl.DateTimeFormat(undefined, optionsDate).format(new Date(data.date_public + '.000Z'));
      articleTitleNameEl.innerHTML = data.title_article;
      articleUserNameEl.innerHTML = `<a href="${data.user_profile_url}">by ${data.user_name}</a>`;

      if (articleUserAvatarImgEl && data.user_avatar_small && data.user_avatar_small != '') {
        let avatarImg = document.createElement("img");
        avatarImg.setAttribute("src", data.user_avatar_small);
        avatarImg.setAttribute("class", " ");
        let avatarUrlProfile = document.createElement("a");
        avatarUrlProfile.setAttribute("href", data.user_profile_url);
        avatarUrlProfile.append(avatarImg);
        articleUserAvatarImgEl.append(avatarUrlProfile);
      }


      if (viewListTree == 0 && data.type_item_article == 21) {
        articleContentContainerEl.innerHTML = `<div class="article-blog-content">
            <div class="article-blog-item-text-container">
                <div class="article-blog-item-title" id="article-container-title"></div>
                <div class="article-blog-item-text" id="article-container-text"></div>
        </div>`;

        const articleContainerTextEl = document.getElementById('article-container-title');
        const articleContainerTextTitleEl = document.getElementById('article-container-text');
        articleContainerTextEl.innerHTML = data.title_article;
        articleContainerTextTitleEl.innerHTML = data.description;
        base.tagListItem(itemTagEl, data.type_item_article, data.tag_item);


        viewListTree = 1;
      }
      else if (viewListTree == 0 && data.type_item_article == 23) {
        articleContentContainerEl.innerHTML = `<div class="article-manual-content"><div class="article-content-left">
            <div class="manual-item-left-container" id="article-manual-tree-list-item"></div>
        </div>
            <div class="article-manual-item-text-container">
                <div class="article-manual-item-title" id="manual-item-title"></div>
                <div class="article-manual-item-text" id="manual-item-text"></div>
            </div>
        </div>`;

        manualTextEl = document.getElementById('manual-item-text');
        manualTextTitleEl = document.getElementById('manual-item-title');
        manualTextTitleEl.innerHTML = data.title_article;
        manualTextEl.innerHTML = data.description;

        handleGetItemManualTreeData(data.type_item, data.item_id);
        viewListTree = 1;
      }
      else if (viewListTree == 0 && data.type_item_article == 26) {

        articleContentContainerEl.innerHTML = `<div class="article-manual-content"><div class="article-content-left">
            <div class="manual-item-left-container" id="article-manual-tree-list-item"></div>
        </div>
            <div class="article-changelog-content"> <div class="article-changelog-text-container">
                <div class="article-changelog-text-container__title" id="article-container-title"></div>
                <div class="article-changelog-text-container__text" id="article-container-text"></div></div>
            </div>
        </div>`;

        const articleContainerTextEl = document.getElementById('article-container-title');
        const articleContainerTextTitleEl = document.getElementById('article-container-text');
        articleContainerTextEl.innerHTML = data.item_name;
        articleContainerTextTitleEl.innerHTML = data.description;

        itemCommentsTypeEl.innerHTML = `<div class="article-comments-type-content__comment-type">Comments: ${data.number_comments}</div>
        <div class="article-comments-type-content__comment-type">Bug: (${data.comments_bug}/${data.comments_bug_closed})</div>
        <div class="article-comments-type-content__comment-type">Question: ${data.comments_question}</div>
        <div class="article-comments-type-content__comment-type">Proposal: ${data.comments_proposal}</div>
        <div class="article-comments-type-content__comment-type">Review: ${data.comments_review}</div>
        <div class="article-comments-type-content__comment-type">Resolution: ${data.comments_resolution}</div>`;
      }

      else if (viewListTree == 0 && data.type_item_article == 32) {        
        howToUseContentContainerEl.innerHTML = base.howToUseItems(data, true, base.getTypeNameByVal(32), true);
        base.bindOnClickEventPlayAnimation();
      }
     

      if (data.number_comments && data.number_comments != 0) {
        comments.handleGetItemCommentsData(commentsListArticleEl, TYPE_ARTICLE_ID, CHANGELOG_ID, USER_ID);
      }

    },
    error: function (error) {
      console.log(error);
    }
  });
};


const handleGetItemManualTreeData = (typeItem, itemId) => {
  $.ajax({
    type: 'GET',
    url: `/article-manual-tree-json/${typeItem}/${itemId}/`,
    success: function (response) {
      //let max_size = response.max;
      const data = response.data;
      const manualTreeListEl = document.getElementById('article-manual-tree-list-item');
      var htmlTree = `<div class="lib-tree-level"><span class="indent"></span><ul class="lib-tree-items-list">`;
      // setTimeout(() =>{        
      data.map(item => {

        if (item.root_id == 0 && item.sequence_number == 0 && manualIdFirstOpen == 0) {
          manualIdFirstOpen = item.item_id;
        }

        if (item.root_id == 0) {
          if (item.number_children == 0) {
            htmlTree += `<li><a href="#${item.item_id}" class="lib-tree-item" data-value="${item.item_id}">${item.name_item}<span class="lib-tree-item__count"></span></a></li>`;
          }
          else {
            htmlTree += `<a href="#${item.item_id}" class="lib-tree-item lib-tree-item__top collapsed" data-bs-toggle="collapse" type="button" role="button" 
                         data-bs-target="#tree-${item.item_id}-collapse" aria-expanded="true" data-value="${item.item_id}"> ${item.name_item} <span class="lib-tree-item__count"></span>
                             <div class="lib-tree-list-open">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="lib-icon lib-icon-size-18 lib-icon-no-fill" fill="#ff0000" stroke-width="2" height="18px" viewBox="0 0 24 24" width="18px">
                                     <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                 </svg>
                             </div>
                             <div class="lib-tree-list-close" >
                                <svg xmlns="http://www.w3.org/2000/svg" class="lib-icon lib-icon-size-18 lib-icon-no-fill" fill="#ff0000" stroke-width="2" height="18px" viewBox="0 0 24 24" width="18px">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                </svg>
                              </div>
                          </a>
                          <div class="lib-tree-level collapse show" id="tree-${item.item_id}-collapse">
                          <span class="indent"></span><ul class="lib-tree-items-list">`;

            htmlTree += manualTreeBuild(item.item_id, data);
            htmlTree += `</ul></div>`;
          }
        }
      });

      htmlTree += `</ul></div>`;
      manualTreeListEl.innerHTML = htmlTree;

      handleGetItemManualArticleData(manualIdFirstOpen);

      const manualArticleList = [].slice.call(document.querySelectorAll('a[data-value]'));
      
      manualArticleList.forEach(function (manualArticleListItem) {
        manualArticleListItem.addEventListener("click", function (e) {
          let idManual = e.target.href;
          if (idManual == undefined) return;

          const hash = idManual.split("#");
          //це потріно що якщо хтось клікає в дереві туж позицію не перевантажувались дані з сервера
          if (currentViewManualId != hash[1]) {
            currentViewManualId = hash[1];
            handleGetItemManualArticleData(hash[1]);
          }
        });
      });
    },
    error: function (error) {
      console.log(error);
    }
  });
};

function manualTreeBuild(rootId, dataTree) {
  var htmlTree = ``;
  console.log('test tree: ');
  console.log(rootId);
  dataTree.map(item => {

    if (item.root_id == rootId) {
      if (item.number_children == 0) {
        htmlTree += `<li><a href="#${item.item_id}" class="lib-tree-item" data-value="${item.item_id}">${item.name_item}<span class="lib-tree-item__count"></span></a></li>`;
      }
      else {
        htmlTree += `<a href="#${item.item_id}" class="lib-tree-item lib-tree-item__top collapsed" data-bs-toggle="collapse" type="button" role="button"
                     data-bs-target="#tree-${item.item_id}-collapse" aria-expanded="true" data-value="${item.item_id}"> ${item.name_item} <span class="lib-tree-item__count"></span>
                         <div class="lib-tree-list-open">
                             <svg xmlns="http://www.w3.org/2000/svg" class="lib-icon lib-icon-size-18 lib-icon-no-fill" fill="#ff0000" stroke-width="2" height="18px" viewBox="0 0 24 24" width="18px">
                                 <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                             </svg>
                         </div>
                         <div class="lib-tree-list-close">
                             <svg xmlns="http://www.w3.org/2000/svg" class="lib-icon lib-icon-size-18 lib-icon-no-fill" fill="#ff0000" stroke-width="2" height="18px" viewBox="0 0 24 24" width="18px">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                             </svg>
                         </div>
                      </a>
                      <div class="lib-tree-level collapse show" id="tree-${item.item_id}-collapse">
                      <span class="indent"></span><ul class="lib-tree-items-list">`;

        htmlTree += manualTreeBuild(item.item_id, dataTree);
        htmlTree += `</ul></div>`;
      }
    }
  });
  return htmlTree;
}

const handleGetItemManualArticleData = (itemId) => {
  $.ajax({
    type: 'GET',
    url: `/article-manual-json/${itemId}/`,
    success: function (response) {
      const data = response.data;

      manualTextTitleEl.innerHTML = data.title_article;
      manualTextEl.innerHTML = data.description;
      base.tagListItem(itemTagEl, data.type_item_article, data.tag_item);

    },
    error: function (error) {
      console.log(error);
    }
  });
};

export { initializeArticlieViewerPage };