import $ from "jquery/dist/jquery";

let USER_ID = 0;

export const handleGetItemCommentsData = (commentsElementById, typeItem, itemId, userId) => {
    USER_ID = userId;
    $.ajax({
      type: 'GET',
      url: `/comments-json/${typeItem}/${itemId}/20/`,
      success: function (response) {
        const data = response.data;      

        commentsElementById.innerHTML = '';
  
  
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var optionsDate = {
          timeZone: timezone,
          timeStyle: "short",
          dateStyle: "short"
        };
  
  
        let htmlTree = `<div class="comments-tree-level"><ul class="comments-ul">`;
  
        data.map(item => {

          if (item.parent_id == 0) {
            if (item.number_children == 0) {
              htmlTree += `<li class="comments-li">${itemCommentsData(item, optionsDate, 0)}</li>`;
            }
            else {
              htmlTree += `<li class="comments-li">${itemCommentsData(item, optionsDate, 0)}`;
              htmlTree += `<ul class="comments-tree-items-list">`;
              htmlTree += itemCommentsTreeData(data, item.comments_id, optionsDate);
              htmlTree += `</ul></li>`;
            }
          }
        });
        htmlTree += `</ul></li></div>`;
        commentsElementById.innerHTML += htmlTree;

      },
      error: function (error) {
        console.log(error);
      }
    });
  };
  
  const itemCommentsTreeData = (itemRoot, parentId, optionsDate) => {
    let htmlTree = ``;
    itemRoot.map(item => {
      if (item.parent_id == parentId) {
        if (item.number_children == 0) {
          htmlTree += `<li class="comments-li">${itemCommentsData(item, optionsDate, 0)}</li>`;
        }
        else {
          htmlTree += `<li class="comments-li">${itemCommentsData(item, optionsDate, 0)}`;
          htmlTree += `<ul class="comments-tree-items-list">`;
          htmlTree += itemCommentsTreeData(itemRoot, item.comments_id, optionsDate);
          htmlTree += `</ul></li>`;
        }
      }
    });
    return htmlTree;
  };
  
  
  const itemCommentsData = (item, optionsDate) => {
    let commentData = '';
  
    var labelOwner = '';
    if (item.user_id == USER_ID) {
      labelOwner = 'Owner';
    }
  
    var typeCommentName = '';
    var typeCommentLabel = '';
  
    if (item.type_comment == 2) {
      typeCommentName = 'Question';
      typeCommentLabel = 'question';
    }
    else if (item.type_comment == 3) {
      typeCommentName = 'Proposal';
      typeCommentLabel = 'proposal';
    }
    else if (item.type_comment == 4) {
      typeCommentName = 'Review';
      typeCommentLabel = 'review';
    }
    else if (item.type_comment == 5) {
      typeCommentName = 'Bug';
      typeCommentLabel = 'bug';
    }
    else if (item.type_comment == 6) {
      typeCommentName = 'Resolution';
      typeCommentLabel = 'resolution';
    }
  
  
    var footerLableList = '';


    item.reaction_json.map(item => {
      footerLableList += `<div class="libitems-comments__footer__like-status__status-container" data-bs-toggle="tooltip" title="${item.tooltip}">
      <div class="libitems-comments__footer__like-status__status-container__icon">
          <img src="${commentTypeLabelIcon(item.type)}">
      </div>
      <div class="libitems-comments__footer__like-status__status-container__number">${item.number}</div>
  </div>`;

    });
    
    var ownerMark = '';
    if (item.owner_mark_text != '') {
      ownerMark = `<div class="libitems-comments__footer__owner-mark"> ${item.owner_mark_text} <a href="${item.owner_mark_url}"> #${item.owner_mark_id}</a></div>`;
    }
  
  
    commentData = `<div class="libitems-comments">
          <div class="libitems-comments__title">
              <a class="libitems-comments__title__nikname" href="/profile/${item.user_id}/">${item.user_name}</a>
              <div class="libitems-comments__title__label-owner">${labelOwner}</div>
              <div class="libitems-comments__title__type-comment">
                  <div class="libitems-comments__title__type-comment__icon ${typeCommentLabel}">${typeCommentName}</div>
              </div>
              <div class="libitems-comments__title__date-public">${new Intl.DateTimeFormat(undefined, optionsDate).format(new Date(item.date_public + '.000Z'))}</div>
              <div class="libitems-comments__title__reply"><a href="#"><img src="/static/assets/icons/png/reply_FILL0_wght400_GRAD0_opsz40.png"></a></div>
          </div>
          <div class="libitems-comments__body">${item.text_comment}</div>
          <div class="libitems-comments__footer">
              <div class="libitems-comments__footer__like-status">
                ${footerLableList}
              </div>
              ${ownerMark}
          </div>
      </div>`;
    return commentData;
  };

  function commentTypeLabelIcon(typeLabelIcon) {
    var urlIcon = `/static/assets/icons/png/`;

  if (typeLabelIcon === 1) {
      urlIcon += 'thumbs_up_3d_default.webp';
  }
  else if (typeLabelIcon === 2) {
      urlIcon += 'thumbs_down_3d_default.webp';
  }
  else if (typeLabelIcon === 3) {
      urlIcon += 'lady_beetle_3d.webp';
  }
  else if (typeLabelIcon === 4) {
      urlIcon += 'lady_beetle_3d.webp';
  }
  else if (typeLabelIcon === 5) {
      urlIcon += 'bookmark_3d.webp';
  }
  else if (typeLabelIcon === 6) {
      urlIcon += 'angry_face_3d.webp';
  }
  else if (typeLabelIcon === 7) {
      urlIcon += 'rocket_3d.webp';
  }
  else if (typeLabelIcon === 8) {
      urlIcon += 'fire_3d.webp';
  }
  else if (typeLabelIcon === 9) {
      urlIcon += 'slightly_smiling_face_3d.webp';
  }
  else if (typeLabelIcon === 10) {
      urlIcon += 'slightly_frowning_face_3d.webp';
  }
  else if (typeLabelIcon === 11) {
      urlIcon += 'confused_face_3d.webp';
  }
  else if (typeLabelIcon === 12) {
      urlIcon += 'crying_face_3d.webp';
  }
  else if (typeLabelIcon === 13) {
      urlIcon += 'party_popper_3d.webp';
  }
  else if (typeLabelIcon === 14) {
      urlIcon += 'partying_face_3d.webp';
  }
  else if (typeLabelIcon === 15) {
      urlIcon += '1st_place_medal_3d.webp';
  }
  else if (typeLabelIcon === 16 ) {
      urlIcon += 'adhesive_bandage_3d.webp';
  }
  else if (typeLabelIcon === 17 ) {
      urlIcon += 'anxious_face_with_sweat_3d.webp';
  }
  else if (typeLabelIcon === 18 ) {
      urlIcon += 'astonished_face_3d.webp';
  }
  else if (typeLabelIcon === 19 ) {
      urlIcon += 'balloon_3d.webp';
  }
  else if (typeLabelIcon === 20 ) {
      urlIcon += 'beaming_face_with_smiling_eyes_3d.webp';
  }
  else if (typeLabelIcon === 21 ) {
      urlIcon += 'beer_mug_3d.webp';
  }
  else if (typeLabelIcon === 22 ) {
      urlIcon += 'exploding_head_3d.webp';
  }
  else if (typeLabelIcon === 23 ) {
      urlIcon += 'face_holding_back_tears_3d.webp';
  }
  else if (typeLabelIcon === 24 ) {
      urlIcon += 'face_savoring_food_3d.webp';
  }
  else if (typeLabelIcon === 25 ) {
      urlIcon += 'face_screaming_in_fear_3d.webp';
  }
  else if (typeLabelIcon === 26) {
      urlIcon += 'face_with_symbols_on_mouth_3d.webp';
  }
  else if (typeLabelIcon === 27 ) {
      urlIcon += 'face_with_tears_of_joy_3d.webp';
  }
  else if (typeLabelIcon === 28 ) {
      urlIcon += 'face_with_tongue_3d.webp';
  }
  else if (typeLabelIcon === 29) {
      urlIcon += 'grinning_squinting_face_3d.webp';
  }
  else if (typeLabelIcon === 30 ) {
      urlIcon += 'heart_on_fire_3d.webp';
  }
  else if (typeLabelIcon === 31 ) {
      urlIcon += 'hot_face_3d.webp';
  }
  else if (typeLabelIcon === 32) {
      urlIcon += 'megaphone_3d.webp';
  }
  else if (typeLabelIcon === 33 ) {
      urlIcon += 'pensive_face_3d.webp';
  }
  else if (typeLabelIcon === 34 ) {
      urlIcon += 'pill_3d.webp';
  }
  else if (typeLabelIcon === 35 ) {
      urlIcon += 'pouting_face_3d.webp';
  }
  else if (typeLabelIcon === 36 ) {
      urlIcon += 'rolling_on_the_floor_laughing_3d.webp';
  }
  else if (typeLabelIcon === 37 ) {
      urlIcon += 'smiling_face_with_heart-eyes_3d.webp';
  }
  else if (typeLabelIcon === 38 ) {
      urlIcon += 'smiling_face_with_sunglasses_3d.webp';
  }
  else if (typeLabelIcon === 39) {
      urlIcon += 'red_heart_3d.webp';
  }
  else if (typeLabelIcon === 40 ) {
      urlIcon += 'star_3d.webp';
  }
  else if (typeLabelIcon === 41 ) {
      urlIcon += 'trophy_3d.webp';
  }
  else if (typeLabelIcon === 42 ) {
      urlIcon += 'winking_face_with_tongue_3d.webp';
  }
  else if (typeLabelIcon === 43) {
      urlIcon += 'zany_face_3d.webp';
  }
  else if (typeLabelIcon === 44) {
      urlIcon += 'zipper-mouth_face_3d.webp';
  }
  else if (typeLabelIcon === 45) {
      urlIcon += 'check_mark_owner_accept_bug.webp';
  }
  else if (typeLabelIcon === 46) {
      urlIcon += 'check_mark_owner_fix_bug.webp';
  }

    return urlIcon;
  }