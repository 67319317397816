import $ from "jquery/dist/jquery";
import * as base from "./base";
const profileItemsContainer = document.getElementById('profile-list-view');
const profileSpinnerContainer = document.getElementById('profile-spinner-container');
const profileLoadButton = document.getElementById('profile-load-btn');
const profileLoadContainer = document.getElementById('profile-loading-container');
const profileNameEl = document.getElementById('profile-name');
const profileAvatarEl = document.getElementById('libitems-profile-avatar');
const profileBannerEl = document.getElementById('libitems-profile-banner-cover-img');
const profileAboutEl = document.getElementById('profile-about-view');

const profileSocialFacebookEl = document.getElementById('profile-social-network-facebook');
const profileSocialTwitterEl = document.getElementById('profile-social-network-twitter');
const profileSocialLibitemsEl = document.getElementById('profile-social-network-libitems');
const profileSocialMyspaceEl = document.getElementById('profile-social-network-myspace');
const profileSocialLinkedinEl = document.getElementById('profile-social-network-linkedin');
const profileSocialPinterestEl = document.getElementById('profile-social-network-pinterest');
const profileSocialInstagramEl = document.getElementById('profile-social-network-instagram');
const profileSocialYoutubeEl = document.getElementById('profile-social-network-youtube');
const profileSocialVimeoEl = document.getElementById('profile-social-network-vimeo');
const profileSocialArtstationEl = document.getElementById('profile-social-network-artstation');
const profileSocialGithubEl = document.getElementById('profile-social-network-github');

const initial_number_per_request = 20;
let USER_ID = 0;
let numberItemsVisible = initial_number_per_request;
let clear_list_view = true;
var categoryTypeId = 2;

const menuItem = document.getElementsByClassName('libitems-profile-main-links__item');


function initializeProfilePage() {
    console.log('profile page has been initialized');
    USER_ID = getUserIdFromUrl(location.pathname);
    bindEventsToMenuItems();
    handleGetProfileData(USER_ID);

    profileLoadButton.addEventListener('click', () => {
      numberItemsVisible += initial_number_per_request;
      clear_list_view = false;
      handleGetProfileItemsByCategoryId(USER_ID, categoryTypeId);
      });

      let url = location.href;
      if (location.hash) {
        const hash = url.split("#");
        var typeTabPage = hash[1];

        for (let index = 0; index < menuItem.length; index++) {
          const element = menuItem[index];
          if (element.getAttribute("href").replace(/#/, "") == typeTabPage ) {
            element.click();
          }
        }
      }
      else{
        handleGetProfileItemsByCategoryId(USER_ID, categoryTypeId);
      }
}

const getUserIdFromUrl = (url) => {  
  const regex = /\/profile\/(\d+)\//;
  const match = url.match(regex);
  if (match) {
    return match[1];
  }
  console.error(`Error: Unable to parse URL in getUserIdFromUrl function`);
  return null;
};

const bindEventsToMenuItems = () => {  
  console.log("Invoked bindEventsToTreeCategories");  
  for (let index = 0; index < menuItem.length; index++) {
    const element = menuItem[index];
    if (element.getAttribute("data-value") != undefined && element.getAttribute("data-value") != null) {
      element.addEventListener("click", handleMenuItemClick, false);
    }
  }
};

const handleMenuItemClick = (event) => {
  console.log('Menu item has been clicked');
  
  profileSpinnerContainer.classList.remove('not-visible');
  profileItemsContainer.classList.add('not-visible');
  profileLoadContainer.classList.add('not-visible');
  
  numberItemsVisible = initial_number_per_request;
  clear_list_view = true;
  let categoryId = event.target.getAttribute("data-value");
  if (categoryId == undefined || categoryId == null) {
    categoryId = event.delegateTarget.getAttribute("data-value");
  }
  
  if(categoryTypeId == 1)
  {
    profileItemsContainer.classList.remove('materials-item');
  }
  else if(categoryTypeId == 2)
  {
    profileItemsContainer.classList.remove('models-item');
  }
  else if(categoryTypeId == 3)
  {
    profileItemsContainer.classList.remove('textures-item');
  }
  else if(categoryTypeId == 5)
  {
    profileItemsContainer.classList.remove('addons-item');
  }
  else if(categoryTypeId == 21)
  {
    profileItemsContainer.classList.remove('blog-item');
  }

  if(categoryId == 1)
  {
    profileItemsContainer.classList.add('materials-item');
  }
  else if(categoryId == 2)
  {
    profileItemsContainer.classList.add('models-item');
  }
  else if(categoryId == 3)
  {
    profileItemsContainer.classList.add('textures-item');
  }
  else if(categoryId == 5)
  {
    profileItemsContainer.classList.add('addons-item');
  }
  else if(categoryId == 21)
  {
    profileItemsContainer.classList.add('blog-item');
  }

  categoryTypeId = categoryId;

  window.history.replaceState('', '', event.delegateTarget.getAttribute("href"));

  console.log(event.target.getAttribute("data-value"));
  if (categoryId == 2012) {
    handleGetProfileAbout(USER_ID);
  } else {

    handleGetProfileItemsByCategoryId(USER_ID, categoryId);
  }
};

const handleGetProfileItemsByCategoryId = (userId, categoryId) => {
    profileAboutEl.innerHTML = "";
    profileSpinnerContainer.classList.remove('not-visible');
    $.ajax({
      type: 'GET',
      url: `/profile-json/${userId}/${categoryId}/${numberItemsVisible}/`,
      success: function(response) {        
        let max_size = response.max;
        const data = response.data;
        if (clear_list_view) {
          profileItemsContainer.innerHTML = "";
        }

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var optionsDate = {
          timeZone: timezone,
          timeStyle: "short",
          dateStyle: "short"
      };

        setTimeout(() =>{
          data.map(item => { 
            profileItemsContainer.innerHTML += base.itemDataForList(item, optionsDate);
          });
          if (max_size) {            
            profileLoadContainer.classList.add('not-visible');
            clear_list_view = true;
          } else {
            profileLoadContainer.classList.remove('not-visible');
          }
          profileSpinnerContainer.classList.add('not-visible');
          profileItemsContainer.classList.remove('not-visible');
        }, 100);
        if(data.length == 0) {
            profileLoadContainer.classList.add('not-visible');
            if (profileItemsContainer.innerHTML == "") {
              profileItemsContainer.innerHTML = `<div class="no-results-container lib-txt-4"><p>No data available</p></div>`;
              profileItemsContainer.classList.remove(...['lib-profile-grid-container','models-item']);
            }
        } else {
          // very ugly solution but it works
          //these classes should be added only if there is data and before we had case when there was no data and classes were removed
          if (!profileItemsContainer.classList.contains('lib-profile-grid-container')) {
            profileItemsContainer.classList.add('lib-profile-grid-container');
          }          
        }
      },
      error: function(error) {
        console.log(error);
      }
    });
  };

  const handleGetProfileAbout = (userId) => {
    $.ajax({
      type: 'GET',
      url: `/profile-about-json/${userId}/`,
      success: function (response) {        
        const data = response.data;        
        if (clear_list_view) {
          profileSpinnerContainer.classList.add("not-visible");
          profileItemsContainer.innerHTML = "";
          if (data.about) {
            profileAboutEl.innerHTML = data.about;
          } else {
            profileAboutEl.innerHTML = `<div class="no-results-container lib-txt-4"><p>No data available</p></div>`;
          }
        }
      },
      error: function (error) {
        console.log(error);
      }
    });
  };

const setSocialElementProperties = (element, value) => {
  if (!element) {
    return;
  }
  
  let aEl = element.getElementsByTagName("a")[0];
  if (element && value && value != '') {        
    aEl.setAttribute("href", value);
    aEl.setAttribute("style", "display: block");
    element.setAttribute("style", "display: block");
  }  
};

const handleGetProfileData = (userId) => {
    $.ajax({
      type: 'GET',
      url: `/profile-socials-json/${userId}/`,
      success: function(response) {        
        const data = response.data; 
       
        setTimeout(() =>{
          if (profileNameEl && data.public_name && data.public_name != '') {
            profileNameEl.innerHTML = data.public_name;
          } 
          
          if (profileAvatarEl && data.avatar_small && data.avatar_small != '') {            
            profileAvatarEl.children[0].setAttribute("style", "display: none;");
            let avatarImg = document.createElement("img");
            avatarImg.setAttribute("src", data.avatar_small);
            avatarImg.setAttribute("class", " ");
            profileAvatarEl.append(avatarImg);
          }

          if (profileBannerEl && data.cover_url && data.cover_url != '') {
            profileBannerEl.setAttribute("src", data.cover_url);
          } else {
            profileBannerEl.setAttribute("src", `${location.origin}/static/assets/images/profile_banner_default.webp`);
          }
          
                   
         
          setSocialElementProperties(profileSocialArtstationEl, data.social_network_artstation);          
          setSocialElementProperties(profileSocialFacebookEl, data.social_network_facebook);
          setSocialElementProperties(profileSocialGithubEl, data.social_network_github);          
          setSocialElementProperties(profileSocialInstagramEl, data.social_network_instagram);          
          setSocialElementProperties(profileSocialLibitemsEl, data.social_network_libraryitems);
          setSocialElementProperties(profileSocialLinkedinEl, data.social_network_linkedin);
          setSocialElementProperties(profileSocialMyspaceEl, data.social_network_myspace);
          setSocialElementProperties(profileSocialPinterestEl, data.social_network_pinterest);
          setSocialElementProperties(profileSocialTwitterEl, data.social_network_twitter);
          setSocialElementProperties(profileSocialVimeoEl, data.social_network_vimeo);          
          setSocialElementProperties(profileSocialYoutubeEl, data.social_network_youtube);          
        }, 100);
        
      },
      error: function(error) {
        console.log(error);
      }
    });
  };

export {initializeProfilePage};
