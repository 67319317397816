function initializaCaptcha() {
    var head = document.getElementsByTagName('head')[0];
    var js = document.createElement("script");
    js.async = true;
    js.src = "https://www.google.com/recaptcha/api.js?render=6Ldt5IIkAAAAAFcSCpaUFLFNSXiIDG8Fzmwbp4ki";    
    head.appendChild(js);
}

export {
    initializaCaptcha
};